import { HashRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header/Header";
import HeaderAr from "./components/ar/HeaderAr/HeaderAr";
import Footer from "./components/Footer/Footer";
import FooterAr from "./components/ar/FooterAr/FooterAr";
import Home from "./screens/Home/Home";
import HomeAr from "./screens/ar/HomeAr/HomeAr";
import Story from "./screens/Story/Story";
import StoryAr from "./screens/ar/StoryAr/StoryAr";
import History from "./screens/History/History";
import HistoryAr from "./screens/ar/HistoryAr/HistoryAr";
import Ceo from "./screens/Ceo/Ceo";
import CeoAr from "./screens/ar/CeoAr/CeoAr";
import OurTeam from "./screens/OurTeam/OurTeam";
import OurTeamAr from "./screens/ar/OurTeamAr/OurTeamAr";
import Certificate from "./screens/Certificate/Certificate";
import CertificateAr from "./screens/ar/CertificateAr/CertificateAr";
import Products from "./screens/Products/Products";
import ProductsAr from "./screens/ar/ProductsAr/ProductsAr";
import Gallery from "./screens/Gallery/Gallery";
import GalleryAr from "./screens/ar/GalleryAr/GalleryAr";
import VGallery from "./screens/VGallery/VGallery";
import VGalleryAr from "./screens/ar/VGalleryAr/VGalleryAr";
import Product from "./screens/Product/Product";
import ProductAr from "./screens/ar/ProductAr/ProductAr";
import Contact from "./screens/Contact/Contact";
import ContactAr from "./screens/ar/ContactAr/ContactAr";
import Dashboard from "./screens/Dashboard.jsx";
import PageNotFound from "./screens/PageNotFound/PageNotFound.jsx";
import ScrollToHashElement from "./components/ScrollToHashElement";

const App = () => {
  return (
    <Router>
      <ScrollToHashElement />
      {window.location.pathname.startsWith("/ar") ||
      window.location.hash.startsWith("#/ar") ? (
        <HeaderAr />
      ) : (
        <Header />
      )}
      <Routes>
        {/************** English Routing ***********************/}
        <Route index element={<Home />} />
        <Route path="story" element={<Story />} />
        <Route path="history" element={<History />} />
        <Route path="ceo" element={<Ceo />} />
        <Route path="our-team" element={<OurTeam />} />
        <Route path="certificate" element={<Certificate />} />
        <Route path="products">
          <Route index element={<Products />} />
          <Route path=":id">
            <Route path="category-items" element={<Product />} />
          </Route>
        </Route>
        <Route path="gallery" element={<Gallery />} />
        <Route path="vgallery" element={<VGallery />} />
        <Route path="contact" element={<Contact />} />
        <Route path="dashboard" element={<Dashboard />} />
        {/************** Arabic Routing ***********************/}
        <Route path="ar">
          <Route index element={<HomeAr />} />
          <Route path="story" element={<StoryAr />} />
          <Route path="history" element={<HistoryAr />} />
          <Route path="ceo" element={<CeoAr />} />
          <Route path="our-team" element={<OurTeamAr />} />
          <Route path="certificate" element={<CertificateAr />} />
          <Route path="products">
            <Route index element={<ProductsAr />} />
            <Route path=":id">
              <Route path="category-items" element={<ProductAr />} />
            </Route>
          </Route>
          <Route path="gallery" element={<GalleryAr />} />
          <Route path="vgallery" element={<VGalleryAr />} />
          <Route path="contact" element={<ContactAr />} />
        </Route>
        {/************** Page Not Found ***********************/}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {window.location.pathname.startsWith("/ar") ||
      window.location.hash.startsWith("#/ar") ? (
        <FooterAr />
      ) : (
        <Footer />
      )}
    </Router>
  );
};

export default App;
