import { useEffect, useState, useRef } from "react";
import axios from "axios";

import NewProduct from "./NewProduct";

const NewProducts = () => {
  const [newProducts, setNewProducts] = useState([]);
  const [newProductsPhotos, setNewProductsPhotos] = useState({});
  const cache = useRef({});

  useEffect(() => {
    const getItem = async (id) => {
      const url = `/items/getItemById/${id}`;
      if (cache.current[url]) {
        const data = cache.current[url];
        setNewProductsPhotos((curPhotos) => {
          return { ...curPhotos, [id]: data };
        });
      } else {
        try {
          const { data } = await axios.get(url);
          if (!data.error) {
            cache.current[url] = data.item;
            setNewProductsPhotos((curPhotos) => {
              return { ...curPhotos, [id]: data.item };
            });
          }
        } catch (error) {
          console.error(error.message);
        }
      }
    };
    const getNewProducts = async () => {
      const url = "/items/allNewItems?page=0&size=10000&sortBy=addedDate";
      if (cache.current[url]) {
        const data = cache.current[url];
        setNewProducts(data);
        data.forEach((item) => {
          getItem(item.id);
        });
      } else {
        try {
          const { data } = await axios.get(url);
          if (!data.error) {
            cache.current[url] = data.items;
            setNewProducts(data.items);
            data.items.forEach((item) => {
              getItem(item.id);
            });
          }
        } catch (error) {
          console.error(error.message);
        }
      }
    };

    getNewProducts();
  }, []);

  return (
    <section id="prod">
      {newProducts.length > 0 && (
        <div className="container">
          <div className="row">
            <div className="farm_1 text-center clearfix">
              <div className="col-sm-12">
                <h1 className="mgt">NEW PRODUCTS</h1>
              </div>
            </div>
            <div className="prod_1 clearfix">
              <div className="carousel slide" id="myCarousel1">
                <div className="carousel-inner">
                  {[
                    ...Array(
                      Math.trunc(Math.ceil(newProducts.length / 4))
                    ).keys(),
                  ].map((num) => (
                    <div
                      className={`item ${num === 0 ? "active" : ""}`}
                      key={num}
                    >
                      <RenderNewProducts
                        curProducts={newProducts.slice(
                          num * 4,
                          Math.min(num * 4 + 4, newProducts.length)
                        )}
                        itemPhotos={newProductsPhotos}
                      />
                    </div>
                  ))}
                </div>
                <nav>
                  <ul className="control-box pager mgb">
                    <li>
                      <a data-slide="prev" href="#myCarousel1">
                        <i className="glyphicon glyphicon-chevron-left" />
                      </a>
                    </li>
                    <li>
                      <a data-slide="next" href="#myCarousel1">
                        <i className="glyphicon glyphicon-chevron-right" />
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

function RenderNewProducts({ curProducts, itemPhotos }) {
  return curProducts.map((product) => (
    <NewProduct
      image={
        itemPhotos[product.id]?.photoContentType
          ? `data:${itemPhotos[product.id].photoContentType};base64,${
              itemPhotos[product.id].photo
            }`
          : ""
      }
      name={product.name}
      key={product.id}
      id={product.id}
    />
  ));
}

export default NewProducts;
