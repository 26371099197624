import { useState } from "react";
import { Link } from "react-router-dom";
import { validate } from "email-validator";
import axios from "axios";

import "../../Contact/Contact.css";
import "./ContactAr.css";

const initialValues = {
  name: "",
  email: "",
  phone: "",
  message: "",
};
const initialTouched = {
  name: false,
  email: false,
  phone: false,
};
const allTouched = {
  name: true,
  email: true,
  phone: true,
};

const ContactAr = () => {
  const [values, setValues] = useState(initialValues);
  const [touched, setTouched] = useState(initialTouched);
  const [dataStatus, setDataStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState(undefined);

  function onInputChange({ target }) {
    setValues((prevValues) => {
      return {
        ...prevValues,
        [target.name]: target.value,
      };
    });

    if (dataStatus !== 1) setDataStatus(0);

    if (touched[target.name]) {
      setTouched((prevTouched) => {
        return {
          ...prevTouched,
          [target.name]: false,
        };
      });
    }
  }
  function onInputBlur({ target }) {
    if (!touched[target.name]) {
      setTouched((prevTouched) => {
        return {
          ...prevTouched,
          [target.name]: true,
        };
      });
    }
  }
  function sendContactMessage(e) {
    const sendData = async () => {
      try {
        setDataStatus(1); // Sending Message...
        setErrorMessage(undefined);
        const { data } = await axios.post("contact/saveContactUs", {
          username: values.name,
          userEmail: values.email,
          userPhone: values.phone,
          message: values.message,
        });
        if (data.error) {
          setErrorMessage(data.error);
          setDataStatus(3); // Message failure
        } else {
          setDataStatus(2);
          setValues(initialValues);
          setTouched(initialTouched);
          // Message sent successfully
        }
      } catch (error) {
        setErrorMessage(error.message);
        setDataStatus(3); // Message failure
      }
    };

    const fieldsAreValid =
      values["name"].length > 0 &&
      validate(values["email"]) &&
      values["phone"].length > 0;

    if (fieldsAreValid && dataStatus !== 1) {
      sendData();
    } else {
      setTouched(allTouched);
    }
  }

  return (
    <main className="contact-ar">
      <section id="center" className="center_o clearfix">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="center_o1 text-center">
                <h1 className="mgt">تــواصل معنـا</h1>
            
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact" className="clearfix">
        <div className="container">
          <div className="row">
            <div className="contact_1 clearfix">
              <div className="col-sm-4">
                <div className="contact_1rm clearfix">
                  <div className="contact_1r1 mgt clearfix">
                    <span>
                      <i className="fa fa-phone" />
                    </span>
                    <h4 className="contacth">تــواصل هاتفيـا</h4>
                    <p><a href="tel:+201018125556">556 8125 101 20+</a></p>
                    <p><a href="tel:+201018125557">557 8125 101 20+</a></p>
                    <p><a href="tel:+201000039484">484 0039 100 20+</a></p>
                  </div>
                  <div className="contact_1r1 clearfix">
                    <span>
                      <i className="fa fa-phone" />
                    </span>
                    <h4 className="contacth news">البـريد الالكتروني</h4>
<br></br>
<p className="mail mgt"><a href="mailto:info@elwadyelectric.com">info@elwadyelectric.com</a></p>
<p className="mail mgt"><a href="mailto:ceo@elwadyelectric.com">ceo@elwadyelectric.com</a></p>
<p className="mail mgt"><a href="mailto:sales@elwadyelectric.com">sales@elwadyelectric.com</a></p>                 </div>
                  <div className="contact_1r1 clearfix">
                    <span>
                      <i className="fa fa-phone" />
                    </span>
                    <h4 className="contacth">العـنوان</h4>
                    <p><strong>المقر الرئيسي : </strong> الشيخ زايد - بيفرلي هيلز - منطقة البوليجون - عمارة 7 الدور 4D
</p>
                    <p><strong>المصـنع : </strong> مدينة السادات الصناعية المنطقة الصناعية السابعة مبني رقم 7215
</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="contact_1lm clearfix">
                  <div className="contact_1l clearfix">
                    <h4 className="col_1 mgt">تــواصل معنـا</h4>
                    <h3>أكتـب لـنا رسـالتك</h3>
                  </div>
                  <br />
                  <div className="checkout_1l1 clearfix">
                    <div className="col-sm-12 space_left">
                      <h5>
                        <label htmlFor="contact_name">
                          أســمك
                          <span
                            className="required_star"
                            style={{
                              color: `${
                                touched.name && values.name.length === 0
                                  ? "red"
                                  : "#22a877"
                              }`,
                            }}
                          >
                            *
                          </span>
                        </label>
                      </h5>
                      <input
                        id="contact_name"
                        className="form-control"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={onInputChange}
                        onBlur={onInputBlur}
                        style={{
                          borderColor: `${
                            touched.name && values.name.length === 0
                              ? "red"
                              : ""
                          }`,
                        }}
                      />
                      {touched.name && values.name.length === 0 && (
                        <span className="error-message">يـرجي كتـابه أسمك</span>
                      )}
                    </div>
                  </div>
                  <div className="checkout_1l1 clearfix">
                    <div className="col-sm-6 space_left">
                      <h5>
                        <label htmlFor="contact_email">
                          بـريدك الالكـتروني
                          <span
                            className="required_star"
                            style={{
                              color: `${
                                touched.email && !validate(values.email)
                                  ? "red"
                                  : "#22a877"
                              }`,
                            }}
                          >
                            *
                          </span>
                        </label>
                      </h5>
                      <input
                        id="contact_email"
                        className="form-control"
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={onInputChange}
                        onBlur={onInputBlur}
                        style={{
                          borderColor: `${
                            touched.email && !validate(values.email)
                              ? "red"
                              : ""
                          }`,
                        }}
                      />
                      {touched.email && !validate(values.email) && (
                        <span className="error-message">
                          من فضــلك أكتب بريد الالكتروني صحيح
                        </span>
                      )}
                    </div>
                    <div className="col-sm-6 space_left">
                      <h5>
                        <label htmlFor="contact_phone">
                          رقـم هاتـفك
                          <span
                            className="required_star"
                            style={{
                              color: `${
                                touched.phone && values.phone.length === 0
                                  ? "red"
                                  : "#22a877"
                              }`,
                            }}
                          >
                            *
                          </span>
                        </label>
                      </h5>
                      <input
                        id="contact_phone"
                        className="form-control"
                        type="text"
                        name="phone"
                        value={values.phone}
                        onChange={onInputChange}
                        onBlur={onInputBlur}
                        style={{
                          borderColor: `${
                            touched.phone && values.phone.length === 0
                              ? "red"
                              : ""
                          }`,
                        }}
                      />
                      {touched.phone && values.phone.length === 0 && (
                        <span className="error-message">
                          من فضـلك أكتب رقم هاتفك بشكل صحيح
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="checkout_1l1 clearfix">
                    <div className="col-sm-12 space_left">
                      <h5>
                        <label htmlFor="contact_message">أكتـب رسـالتك</label>
                      </h5>
                      <textarea
                        id="contact_message"
                        className="form-control form_1"
                        name="message"
                        value={values.message}
                        onChange={onInputChange}
                      />
                      <h6>
                        <Link
                          className="button"
                          to="#"
                          onClick={sendContactMessage}
                        >
                          أرسـال رسـالتك
                        </Link>
                        {dataStatus === 1 && (
                          <span className="sending-message">
                            أرسـال الرسـالة...
                            <div className="spinner"></div>
                          </span>
                        )}
                        {dataStatus === 2 && (
                          <span className="success-message">
                            تـم الارسـال بنجاح
                            <i className="fa fa-check"></i>
                          </span>
                        )}
                        {dataStatus === 3 && (
                          <span className="failed-message">
                            فشــل في الأرسـال {errorMessage}
                            <i className="fa fa-times"></i>
                          </span>
                        )}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact_2 clearfix">
            <div className="row">
    <div className="col-sm-6">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.599899624728!2d30.636887424973818!3d30.447442099365443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14589b69b0bf2fad%3A0xe14e814b3b956979!2z2LTYsdmD2Kkg2KfZhNmI2KfYr9mJINmE2YTZg9in2KjZhNin2KogRUxXYWR5IENhYmxlcyBDb21wYW55ICwgV0VD!5e0!3m2!1sar!2seg!4v1704401481679!5m2!1sar!2seg"
        style={{ border: 0, width: "560px", height: 400 }}
        allowFullScreen
        title="Location 1"
      />
    </div>
    <div className="col-sm-6">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3452.8259131344116!2d30.942779575554688!3d30.070524174910545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDA0JzEzLjkiTiAzMMKwNTYnNDMuMyJF!5e0!3m2!1sen!2seg!4v1704980511248!5m2!1sen!2seg"
        style={{ border: 0, width: "560px", height: 400 }}
        allowFullScreen
        title="Location 2"
      />
    </div>
  </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ContactAr;
