import { Link } from "react-router-dom";

const NewProduct = ({ image, name, id }) => {
  return (
    <div className="col-sm-3">
      <div className="prod_1im clearfix">
        <div className="prod_1i clearfix">
          <div className="prod_1i1 clearfix">
            <div className="grid clearfix">
              <figure
                className="effect-jazz new-products-figure"
              >
                <Link
                  to={`/ar/products`}
                  style={{
                    display: "block",
                    height: "65px",
                    maxWidth: "240px",
                    margin: "5px auto",
                  }}
                >
                  <img loading="lazy"
                    src={image}
                    className="iw new-item-image"
                    alt={`new-product-${id}`}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Link>
              </figure>
            </div>
          </div>
          <div className="prod_1i2 clearfix" style={{ top: "-20px" }}>
            <h6 className="mgt bg_1">جديد</h6>
          </div>
        </div>
        <div className="prod_1ib text-center clearfix">
          <h5>{name}</h5>
        </div>
      </div>
    </div>
  );
};

export default NewProduct;
