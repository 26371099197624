import { Link } from "react-router-dom";

const Category = ({ id, image, name }) => {
  return (
    <div className="col-md-3 col-sm-6 col-xs-11 main-cat">
      <div className="serv_2i">
        <div className="serv_2i2 position-relative">
          <div className="serv_2i2i">
            <div className="grid">
              <figure className="effect-jazz mb-0">
                <Link to={`/products/${id}/category-items?page=1`}>
                  <img
                    loading="lazy"
                    src={image}
                    className="w-100 cat-image"
                    alt={`category-${id}`}
                  />
                </Link>
              </figure>
            </div>
          </div>
        </div>
        <div className="serv_2i1">
          <p
            style={{
              fontSize: "1.5rem",
              letterSpacing: "0.3px",
              lineHeight: "1.5",
            }}
          >
            {name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Category;
