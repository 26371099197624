import { useEffect, useState } from "react";
import axios from "axios";

import "./History.css";
import ExperienceYear from "./ExperienceYear";

const experienceYears = [
  {
    id: 1,
    year: "2015",
    description:
      "Launched Camel Milk Powder and received SIAL InnovationAwards for these products.",
  },
  {
    id: 2,
    year: "2016",
    description: "Completion of New Juices and UHT Factory.",
  },
  {
    id: 3,
    year: "2017",
    description: "Received multiple awards for CSR contributions.",
  },
  {
    id: 4,
    year: "2018",
    description: "Distinguished Customer Award for consumer experience.",
  },
];

const History = () => {
  const [experienceYears, setExperienceYears] = useState([]);

  useEffect(() => {
    const getExpYears = async () => {
      try {
        const { data } = await axios.get("/exp_years/viewAllExpYear");
        if (!data.error) setExperienceYears(data.expYears);
      } catch (error) {
        console.error(error.message);
      }
    };
    getExpYears();
  }, []);

  return (
    <main>
      <div className="main clearfix">
        <div className="main_1 clearfix">
          <section id="center" className="center_home clearfix">
            <div
              className="carousel fade-carousel slide"
              data-ride="carousel"
              data-interval="4000"
              id="bs-carousel"
            >
              {/* Overlay */}
              <div className="overlay" />
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item slides active">
                  <div className="slide-21" />
                  <div className="hero clearfix">
                    <div className="col-sm-12">
                      <h3 className="new"> CHALLENGES</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section id="team_h">
        <div className="project_m clearfix">
          <div className="container">
            <div className="service_h1 row text-center">
              <div className="col-md-12">
                <h2 className="text-white">Our Challenges</h2>
                <span className="block_icon">
                  <i className="fa fa-bolt" />
                </span>
                <p className="col_3" style={{ fontSize: "19px" }}>
                El Wady Cables Company faced many difficulties and challenges on its way to achieving its goals, but it was able to achieve success in light of all these difficulties thanks to the strength and determination of its men who were determined to overcome all these adversities to achieve the first steps of success on its path, which still has many stops. The goals that must be achieved in cooperation with its customers, as they are partners of the company in all the achievements it achieves
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Start Story */}
      {experienceYears.length > 0 && (
        <div id="story" className="story-box main-timeline-box">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title-box">
                  <h3>Stages of progress
</h3>
                </div>
              </div>
            </div>
            {experienceYears.map((exYear, idx) => (
              <ExperienceYear
                year={exYear.year}
                description={exYear.description}
                reverse={idx % 2 === 1}
                alignDirection={idx % 2 ? "right" : "left"}
                key={exYear.id}
              />
            ))}
          </div>
        </div>
      )}
      {/* End Story */}
    </main>
  );
};

export default History;
