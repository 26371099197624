import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Alert } from "reactstrap";

import "./OurTeam.css";

function renderData(loading, errorMessage, data, itemPhotos) {
  if (loading) {
    return (
      <div
        className="fetching-data-spinner-parent"
        style={{ paddingBottom: "30px" }}
      >
        <div className="fetching-data-spinner"></div>
      </div>
    );
  } else {
    if (errorMessage) {
      return (
        <Alert color="danger" style={{ paddingBottom: "30px" }}>
          {errorMessage}
        </Alert>
      );
    } else {
      return data.length > 0 ? (
        <div className="team-container">
          {data.map((member) => {
            return (
              <div className="member" key={member.id}>
                <div className="image">
                  <img
                    loading="lazy"
                    src={
                      itemPhotos[member.id]?.photoContentType
                        ? `data:${
                            itemPhotos[member.id].photoContentType
                          };base64,${itemPhotos[member.id].photo}`
                        : ""
                    }
                    alt={member.name}
                  />
                </div>
                <div className="member-info">
                  <span className="name">{member.name}</span>
                  <span className="title">{member.jobTitle}</span>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <Alert color="warning" style={{ paddingBottom: "30px" }}>
          No Members found
        </Alert>
      );
    }
  }
}

const OurTeam = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMembersPhotos, setTeamMembersPhotos] = useState({});
  const cache = useRef({});

  useEffect(() => {
    const getMember = async (id) => {
      const url = `team/getTeamById/${id}`;
      if (cache.current[url]) {
        const data = cache.current[url];
        setTeamMembersPhotos((curPhotos) => {
          return { ...curPhotos, [id]: data };
        });
      } else {
        try {
          const { data } = await axios.get(url);
          if (!data.error) {
            cache.current[url] = data.gallery;
            setTeamMembersPhotos((curPhotos) => {
              return { ...curPhotos, [id]: data.team };
            });
          }
        } catch (error) {
          console.error(error.message);
        }
      }
    };
    const getTeamMembers = async () => {
      const url = `team/viewAllTeams`;
      setErrorMessage(undefined);
      setLoading(true);
      if (cache.current[url]) {
        const data = cache.current[url];
        setTeamMembers(data);
        data.forEach((item) => {
          getMember(item.id);
        });
      } else {
        try {
          const { data } = await axios.get(url);
          if (data.error) {
            if (data.error.includes("REP")) setTeamMembers([]);
            else setErrorMessage(data.error);
            setLoading(false);
          } else {
            setTeamMembers(data.teams);
            setLoading(false);
            data.teams.forEach((item) => {
              getMember(item.id);
            });
          }
        } catch (error) {
          setErrorMessage(error.message);
          setLoading(false);
        }
      }
    };

    getTeamMembers();
  }, []);

  return (
    <main>
      <div className="main clearfix">
        <div className="main_1 clearfix">
          <section id="center" className="center_home clearfix">
            <div
              className="carousel fade-carousel slide"
              data-ride="carousel"
              data-interval="4000"
              id="bs-carousel"
            >
              {/* Overlay */}
              <div className="overlay" />
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item slides active">
                  <div className="slide-28" />
                  <div className="hero clearfix">
                    <div className="col-sm-12">
                      <h3 className="new">OUR TEAM</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <section id="special">
        <div className="container">
          <div id="anaimalfeed" className="row">
            <div className="environment_1 text-center clearfix">
              <div className="col-sm-12">
                <span className="col_1">
                  <i className="fa fa-bolt" />
                </span>
                <h3 className="newFont">Our Team</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="team-members">
        {renderData(loading, errorMessage, teamMembers, teamMembersPhotos)}
      </div>
    </main>
  );
};

export default OurTeam;
