import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Alert } from "reactstrap";

import Modal from "../../components/Modal/Modal";
import "./Gallery.css";

function renderData(loading, errorMessage, data, itemPhotos, setModalData) {
  if (loading) {
    return (
      <div className="fetching-data-spinner-parent">
        <div className="fetching-data-spinner"></div>
      </div>
    );
  } else {
    if (errorMessage) {
      return <Alert color="danger">{errorMessage}</Alert>;
    } else {
      return data.length > 0 ? (
        <>
          {data.map((photo) => {
            return (
              <div
                key={photo.id}
                className="col-md-4 col-sm-6 col-xs-11 gallary-image"
              >
                <div
                  className="pop_img"
                  onClick={() => {
                    setModalData({
                      showModal: true,
                      media: itemPhotos[photo.id]?.photoContentType
                        ? `data:${
                            itemPhotos[photo.id].photoContentType
                          };base64,${itemPhotos[photo.id].photo}`
                        : "",
                    });
                  }}
                >
                  <img
                    src={
                      itemPhotos[photo.id]?.photoContentType
                        ? `data:${
                            itemPhotos[photo.id].photoContentType
                          };base64,${itemPhotos[photo.id].photo}`
                        : ""
                    }
                    alt={photo.name}
                  />
                  <div className="pop_over">
                    <h4 className="newfont">{photo.name}</h4>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <Alert color="warning">No data found</Alert>
      );
    }
  }
}

const Gallery = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [gallaryPhotos, setGallaryPhotos] = useState([]);
  const [gallaryItemsPhotos, setGallaryItemsPhotos] = useState({});
  const [modalData, setModalData] = useState({ showModal: false, media: "" });
  const cache = useRef({});

  useEffect(() => {
    const getGalleryPhoto = async (id) => {
      const url = `gallery/getGalleryById/${id}`;
      if (cache.current[url]) {
        const data = cache.current[url];
        setGallaryItemsPhotos((curPhotos) => {
          return { ...curPhotos, [id]: data };
        });
      } else {
        try {
          const { data } = await axios.get(url);
          if (!data.error) {
            cache.current[url] = data.gallery;
            setGallaryItemsPhotos((curPhotos) => {
              return { ...curPhotos, [id]: data.gallery };
            });
          }
        } catch (error) {
          console.error(error.message);
        }
      }
    };
    const getGallaryPhotos = async () => {
      const url = "gallery/viewAllGallery";
      setErrorMessage(undefined);
      setLoading(true);
      if (cache.current[url]) {
        const data = cache.current[url];
        setGallaryPhotos(data);
        setLoading(false);
        data.forEach((item) => {
          getGalleryPhoto(item.id);
        });
      } else {
        try {
          const { data } = await axios.get(`gallery/viewAllGallery`);
          if (data.error) {
            if (data.error.includes("REP")) setGallaryPhotos([]);
            else setErrorMessage(data.error);
            setLoading(false);
          } else {
            cache.current[url] = data.gallery;
            setGallaryPhotos(data.gallery);
            setLoading(false);
            data.gallery.forEach((item) => {
              getGalleryPhoto(item.id);
            });
          }
        } catch (error) {
          setErrorMessage(error.message);
          setLoading(false);
        }
      }
    };

    getGallaryPhotos();
  }, []);

  return (
    <main>
      <Modal
        showModal={modalData.showModal}
        type="photo"
        setModalData={setModalData}
      >
        <img loading="lazy" src={modalData.media} alt="" />
      </Modal>

      <div className="body_section gallary_home">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sec_title center_view">
                <span className="float_text animate-box"> Gallery</span>
                <h3>Photo Gallery</h3>
              </div>
            </div>
            {renderData(
              loading,
              errorMessage,
              gallaryPhotos,
              gallaryItemsPhotos,
              setModalData
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Gallery;
