import { Link } from "react-router-dom";

import "./StoryAr.css";

import about4 from "../../../assets/img/firstory.jpg";
import cowchange from "../../../assets/img/IMG_4810-_1_-_1_-_1_.jpg";

const StoryAr = () => {
  return (
    <main className="story-ar">
      <div className="main clearfix">
        <div className="main_1 clearfix">
          <section id="center" className="center_home clearfix">
            <div
              className="carousel fade-carousel slide"
              data-ride="carousel"
              data-interval="4000"
              id="bs-carousel"
            >
              {/* Overlay */}
              <div className="overlay" />
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item slides active">
                  <div className="slide-31" />
                  <div className="hero clearfix">
                    <div className="col-sm-12">
                      <h3 className="new">قصــتنا</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <section id="inform">
        <div className="container">
          <div className="row">
            <div className="inform_1 clearfix">
              <div className="col-sm-6">
                <div className="inform_1l clearfix">
                  <h2 className="col_1 mgt aboutheadtitle">
                    الــوادي للكـابلات
                  </h2>
                  <p className="newstyle">
                    تأسست شركة الوادي للكابلات عام 2017، ويقع مقرها الرئيسي
                    بمدينة السادات الصناعية على مساحة تصنيع تزيد عن 12 ألف متر
                    مربع، بالإضافة إلى مكاتب التصميم والمعامل ومناطق التخزين
                    المرتبطة بها. شركتنا متخصصة في تصنيع وتوزيع الكابلات
                    الكهربائية.
                  </p>
                  <br />
                  <p className="newstyle">
                    شركة الوادي للكابلات هي إحدى الشركات المتخصصة في إنتاج
                    وتوريد الكابلات عالية الجودة. بفضل التاريخ الغني والالتزام
                    القوي بالتميز، قمنا بتأسيس أنفسنا كاسم موثوق به في صناعة
                    الكابلات. تلبي مجموعة منتجاتنا الواسعة احتياجات مختلف
                    القطاعات بما في ذلك الطاقة والبناء والسيارات وغيرها.
                  </p>
                  <br />
                  <p className="newstyle">
                    نحن نؤمن بشدة بالابتكار والتكنولوجيا المتقدمة. نحن نستثمر
                    باستمرار في البحث والتطوير للبقاء في طليعة الصناعة وتزويد
                    عملائنا بأحدث التطورات في تكنولوجيا الكابلات.
                  </p>
                  <br />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="inform_1r clearfix">
                  <div className="inform_1ri clearfix">
                    <img loading="lazy"
                      src={about4}
                      alt="about"
                      className="iw"
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                  <div className="inform_1ri1 clearfix">
                    <h4 className="col mgt">مــاذا تنتظر ؟</h4>
                    <h5>
                      <Link className="newbutton" to={`/ar/contact`}>
                        أطلـب تسعـيره
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="center_2" className="clearfix">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center clearfix">
              <h1 className="mgt">رسالتنا و رؤيتنا </h1>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="center_1_main clearfix">
              <div className="col-sm-4 center_1_left clearfix">
                <div className="center_1_left_inner clearfix">
                  <h6 className="mgt">
                    <i className="fa fa-key" />
                  </h6>
                  <h4>
                    <Link to="#">قيم شركتنا</Link>
                  </h4>
                  <ul className="classic">
                    <li>الشفافية والوضوح مع عملائنا</li>
                    <li>الجودة هي الأساس</li>
                    <li>عملائنا هم هدفنا</li>
                  </ul>
                </div>
              </div>

              <div className="col-sm-4 center_1_left clearfix">
                <div className="center_1_left_inner clearfix">
                  <h6 className="mgt">
                    <i className="fa fa-eye" />
                  </h6>
                  <h4>
                    <Link to="#"> رؤيتنا </Link>
                  </h4>
                  <p className="innerpa">
                    نسعى لأن نكون الشركة الرائدة في مجال صناعة الكابلات وطاقة
                    المستقبل بمعايير عالمية ومبتكرة
                  </p>
                </div>
              </div>
              <div className="col-sm-4 center_1_left clearfix">
                <div className="center_1_left_inner clearfix">
                  <h6 className="mgt">
                    <i className="fa fa-bullseye" />
                  </h6>
                  <h4>
                    <Link to="#">رســالتنا</Link>
                  </h4>
                  <p className="innerpa">
                    المصداقية والوضوح والألتزام مع عملائنا هم رأس مالنا الحقيقي.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="off">
        <div className="container">
          <div className="row off1">
            <div className="col-md-6">
              <div className="off1r">
                <div className="grid clearfix">
                  <figure className="effect-jazz mainimage mb-0">
                    <Link to="#">
                      <img loading="lazy" src={cowchange} className="w-100" />
                    </Link>
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="off1l">
                <h5 className="col_1">الوادي للكابلات تعني التميز والأبتكار</h5>
                <h2>الـتزامتنا</h2>
                <span style={{ fontSize: 40 }}>
                  <i className="fa fa-bolt col_4" />
                </span>
                <p className="mt-3">
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  التأكد من وجود الموظفين الأكفاء اللازمين.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  الإبلاغ عن جميع الأفعال غير الآمنة والتحقيق فيها.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  توفير بيئة آمنة وصحية.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  الامتثال لجميع التشريعات القانونية والتنظيمية المعمول بها.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  إعداد ومراقبة ومراجعة الجودة بشكل دوري.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  توفير الموارد المناسبة للتنفيذ الفعال.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  القضاء على المخاطر وتقليل المخاطر المتعلقة بالجودة.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  دمج اعتبارات الجودة والصحة والسلامة والبيئة في أعمالنا.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default StoryAr;
