import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>Elwady Cables</h3>
                <p>
               <strong>Headquarters:</strong> ElSheikh Zayed, <br />
               Beverly Hills,
               Bolygon Zone, Building 7 Floor 4D
                  <br />
                
                  <br />
                  <strong>Factory:</strong> El Sadat Industrial City <br />
                Seventh Industrial Zone, Plot No 7215
                  <br />
                  
                  <br />
                  <strong>Phone:</strong> <a href="tel:+201000039484">+20 10000 394 84</a>
                  <br />
                  <strong>Email:</strong> <span className="mainmail">info@elwadyelectric.com</span>
                  <br />
                </p>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4 style={{ marginBottom: "10px" }}>Explore</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/products">Products</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/gallery">Photos</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/vgallery">Videos</Link>
                  </li>
                 
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4 style={{ marginBottom: "10px" }}>Quick</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/story">Our Story</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/history">Challenges </Link>
                  </li>

                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/certificate">Certificates</Link>
                  </li>
                  {/* <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/our-team">Our Team</Link>
                  </li> */}
                </ul>
              </div>
             
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <div className="social-links mt-3">
                <a
                    href="https://www.facebook.com/elwadyelectric?mibextid=9R9pXO"
                    className="facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/elwadycable/"
                    className="instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/elwadycables/"
                    className="linkedin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin" />
                  </a>
                  <a
                    href="https://www.youtube.com/@ElWadyCables"
                    className="youtube"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-youtube" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-4">
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>El Wady Cables</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by
            <a
              href="https://softcreation.net/"
              target="_blank"
              rel="noreferrer"
            >
              Softcreation
            </a>
          </div>
        </div>
      </footer>

      <div
        className="modal fade"
        id="myModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="input-group clearfix">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <span className="input-group-btn">
                  <button className="btn btn-primary" type="button">
                    <i className="fa fa-search" />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
