import { useEffect, useState } from "react";
import axios from "axios";

import "../../History/History.css";
import "./HistoryAr.css";
import ExperienceYear from "./ExperienceYearAr";

const History = () => {
  const [experienceYears, setExperienceYears] = useState([]);

  useEffect(() => {
    const getExpYears = async () => {
      try {
        const { data } = await axios.get("/exp_years/viewAllExpYear");
        if (!data.error) setExperienceYears(data.expYears);
      } catch (error) {
        console.error(error.message);
      }
    };
    getExpYears();
  }, []);

  return (
    <main className="history-ar">
      <div className="main clearfix">
        <div className="main_1 clearfix">
          <section id="center" className="center_home clearfix">
            <div
              className="carousel fade-carousel slide"
              data-ride="carousel"
              data-interval="4000"
              id="bs-carousel"
            >
              {/* Overlay */}
              <div className="overlay" />
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item slides active">
                  <div className="slide-21" />
                  <div className="hero clearfix">
                    <div className="col-sm-12">
                      <h3 className="new">تحديـاتنا</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section id="team_h">
        <div className="project_m clearfix">
          <div className="container">
            <div className="service_h1 row text-center">
              <div className="col-md-12">
                <h2 className="text-white">تحديـات شركـتنا</h2>
                <span className="block_icon">
                  <i className="fa fa-bolt" />
                </span>
                <p className="col_3" style={{ fontSize: "19px" }}>
                واجهت شركة الوادي للكابلات العديد من الصعوبات والتحديات في طريق تقدمها نحو تحقيق أهدافها ، ولكنها استطاعت تحقيق النجاح في ظل كل تلك الصعوبات وذلك بفضل سواعد وعزائم رجالها الذين قد صمموا على تجاوز كل تلك المحن لتحقيق أولى خطوات النجاح على طريقها الذي لا يزال فيه العديد من المحطات و الأهداف المطلوب تحقيقها  وذلك بالتعاون مع عملائها بأعتبارهم شركاء للشركة في كل الانجازات التي تحققها                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Start Story */}
      {experienceYears.length > 0 && (
        <div id="story" className="story-box main-timeline-box">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title-box">
                  <h3>مــراحل التقـدم</h3>
                </div>
              </div>
            </div>
            {experienceYears.map((exYear, idx) => (
              <ExperienceYear
                year={exYear.year}
                description={exYear.arDescription}
                reverse={idx % 2 === 1}
                alignDirection={idx % 2 ? "right" : "left"}
                key={exYear.id}
              />
            ))}
          </div>
        </div>
      )}
      {/* End Story */}
    </main>
  );
};

export default History;
