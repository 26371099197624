/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import "../../Header/Header.css";
import "./HeaderAr.css";

import mainlogo from "../../../assets/img/arLogo.png";
import Language from "../../Language/Language";

const HeaderAr = () => {
  const location = useLocation();

  useEffect(() => {
    $(document).ready(function () {
      /*****Fixed Menu******/
      var secondaryNav = $(".cd-secondary-nav"),
        secondaryNavTopPosition = secondaryNav.offset().top,
        navbar_height = document.querySelector(".navbar").offsetHeight;

      $(window).on("scroll", function () {
        if ($(window).scrollTop() > secondaryNavTopPosition + navbar_height) {
          secondaryNav.addClass("is-fixed");
          document.body.style.paddingTop = navbar_height + "px";
        } else {
          secondaryNav.removeClass("is-fixed");
          document.body.style.paddingTop = "0";
        }
      });
    });
  }, []);

  return (
    <>
      <section className="header-ar">
        <section id="top">
          <div className="container">
            <div className="row">
              <div className="top_1 clearfix">
                <div className="col-sm-3">
                  <div className="top_1r text-left clearfix">
                    <h6 className="big mgt">
                      <Link className="button mgt" to="/ar/contact">
                        <i className="fa fa-envelope-o" />{" "}
                        <span>تواصل معنا</span>
                      </Link>
                    </h6>
                  </div>
                </div>

                <div className="col-sm-5">
                  <div className="top_1l clearfix">
                    <p className="mgt font_16 text-center">
                      <i className="fa fa-map col_1" />
                      <span className="col_6">المقر الرئيسي : </span>{" "}
                      <span>الشيخ زايد - بيفرلي هيلز - منطقة البوليجون</span>
                    </p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="top_1m text-center clearfix">
                    <h3 className="mgt">
                      <a href="/#/ar" className="logo-container">
                        <img loading="lazy" className="logo" alt="abc" src={mainlogo} />
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="header" className="clearfix cd-secondary-nav nav-ar">
          <nav className="navbar nav_t">
            <div className="container">
              <div className="navbar-header page-scroll">
                <button
                  type="button"
                  className="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
                <a className="navbar-brand logo-container" href="/#/ar">
                  <img loading="lazy" alt="abc" src={mainlogo} />
                </a>
              </div>

              <div
                className="collapse navbar-collapse nav-holder"
                id="bs-example-navbar-collapse-1"
              >
                <ul className="nav navbar-nav">
                  <li>
                    <Link
                      to="/ar"
                      className={`m_tag ${
                        location.pathname === "/ar" ||
                        location.pathname === "/ar/"
                          ? "active_tab"
                          : ""
                      }`}
                    >
                      الــرئيسية
                    </Link>
                  </li>
                  <li className="dropdown">
                    <a
                      className={`m_tag ${
                        location.pathname.startsWith("/ar/story") ||
                        location.pathname.startsWith("/ar/history") ||
                        location.pathname.startsWith("/ar/ceo") ||
                        location.pathname.startsWith("/ar/certificate") ||
                        location.pathname.startsWith("/ar/our-team")
                          ? "active_tab"
                          : ""
                      }`}
                      href="#"
                      data-toggle="dropdown"
                      role="button"
                      aria-expanded="false"
                    >
                      عـن الــشركة
                      <span className="caret" />
                    </a>
                    <ul className="dropdown-menu drop_3" role="menu">
                      <li>
                        <Link to="/ar/story"> قصــتنا</Link>
                      </li>
                      <li>
                        <Link to="/ar/story/#center_2" className="border_none">
                          الــرؤية والــرسالة
                        </Link>
                      </li>
                      <li>
                        <Link to="/ar/history" className="border_none">
                          التحديـات
                        </Link>
                      </li>

                      <li>
                        <Link to="/ar/ceo" className="border_none">
                          كـلمة رئــيس مجلس الادارة
                        </Link>
                      </li>

                      <li>
                        <Link to="/ar/certificate" className="border_none">
                          الشــهادات 
                        </Link>
                      </li>

                      {/* <li>
                        <Link to="/ar/our-team" className="border_none">
                        فريق العمل
                        </Link>
                      </li> */}
                    </ul>
                  </li>

                  <li>
                    <Link
                      to="/ar/products"
                      className={`m_tag ${
                        location.pathname === "/ar/products" ? "active_tab" : ""
                      }`}
                    >
                      منتــجاتنا
                    </Link>
                  </li>
                  <li className="dropdown">
                    <a
                      className={`m_tag ${
                        location.pathname.startsWith("/ar/gallery") ||
                        location.pathname.startsWith("/ar/vgallery")
                          ? "active_tab"
                          : ""
                      }`}
                      href="#"
                      data-toggle="dropdown"
                      role="button"
                      aria-expanded="false"
                    >
                      المعــرض
                      <span className="caret" />
                    </a>
                    <ul className="dropdown-menu drop_3" role="menu">
                      <li>
                        <Link to="/ar/gallery" className="border_none">
                          الصــور
                        </Link>
                      </li>
                      <li>
                        <Link to="/ar/vgallery" className="border_none">
                          الفيــديوهات
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link
                      to="/ar/contact"
                      className={`m_tag ${
                        location.pathname === "/ar/contact" ? "active_tab" : ""
                      }`}
                    >
                      الاتصــال بنا
                    </Link>
                  </li>
                </ul>
                <ul className="nav navbar-nav navbar-left">
                  {/* ------------------ Language icon ------------------------ */}
                  <Language location={location} />
                </ul>
              </div>
              {/* /.navbar-collapse */}
            </div>
            {/* /.container-fluid */}
          </nav>
        </section>
      </section>
    </>
  );
};

export default HeaderAr;
