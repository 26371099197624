import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Modal.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export default function Modal({ showModal, type, setModalData, children }) {
  return (
    <div
      className={`media-modal ${type === "video" ? "video-type" : ""}`}
      style={{
        opacity: showModal ? "1" : "0",
        visibility: showModal ? "visible" : "hidden",
      }}
    >
      <FontAwesomeIcon
        icon={faXmark}
        className="close-icon"
        onClick={(e) => {
          setModalData((curData) => {
            return type === "video"
              ? { ...curData, media: "", showModal: false }
              : { ...curData, showModal: false };
          });
        }}
      />
      <div className="media">{children}</div>
    </div>
  );
}
