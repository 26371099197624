import { Link } from "react-router-dom";
import "../../Footer/Footer.css";
import "./FooterAr.css";

const FooterAr = () => {
  return (
    <>
      <footer id="footer" className="footer-ar">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact newcontact">
                <h3>الــوادي للكـابلات</h3>
                <p><strong>المقر الرئيسي :</strong>
                الشيخ زايد - بيفرلي هيلز<br />
                منطقة البوليجون - عمارة 7 الدور 4D
                  <br />
                  <br />
                  <strong>المصنع  :</strong>
                  مدينة السادات الصناعية <br />
                  المطقة الصناعية السابعة, مبني رقم 7215
                  <br />
                  <br />
                  <strong>الهـاتف :</strong><a href="tel:+201000039484"> 84 394 10000 20+  </a><br></br>
                  <br />
                  <strong>البـريد :</strong> <span className="mainmail">info@elwadyelectric.com</span>
                  <br />
                </p>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4 style={{ marginBottom: "10px" }}>روابـط</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/ar">الرئـيسية</Link>
                  </li>

                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/ar/products">منتـجاتنا</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/ar/gallery"> الصـور</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/ar/vgallery"> الفيـديوهات</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/ar/contact">تــواصل معنا</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4 style={{ marginBottom: "10px" }}>ســريعة</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/ar/story">قصــتنا</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/ar/history">التحديـات</Link>
                  </li>

                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/ar/certificate">الشــهادات </Link>
                  </li>
                  {/* <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/ar/our-team">فريـق العمـل</Link>
                  </li> */}
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>شبكاتنا الاجتماعية</h4>
                <div className="social-links mt-3">
                  
                  <a
                    href="https://www.facebook.com/elwadyelectric?mibextid=9R9pXO"
                    className="facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/elwadycable/"
                    className="instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/elwadycables/"
                    className="linkedin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin" />
                  </a>
                  <a
                    href="https://www.youtube.com/@ElWadyCables"
                    className="youtube"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-youtube" />
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-4">
          <div className="copyright">
            كل الحقوق © محفوظة لشـركة
            <strong>
              <span>الـوادي للكـابلات</span>
            </strong>
          </div>
          <div className="credits">
            تم التصـميم بواسطة
            <a
              href="https://softcreation.net/"
              target="_blank"
              rel="noreferrer"
            >
              Softcreation
            </a>
          </div>
        </div>
      </footer>

      <div
        className="modal fade footer-modal-ar"
        id="myModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="input-group clearfix">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <span className="input-group-btn">
                  <button className="btn btn-primary" type="button">
                    <i className="fa fa-search" />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterAr;
