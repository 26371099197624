import "./Ceo.css";

import ceo from "../../assets/img/ceo.jpg";

const Ceo = () => {
  return (
    <main>
      <div className="main clearfix">
        <div className="main_1 clearfix">
          <section id="center" className="center_home clearfix">
            <div
              className="carousel fade-carousel slide"
              data-ride="carousel"
              data-interval="4000"
              id="bs-carousel"
            >
              {/* Overlay */}
              <div className="overlay" />
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item slides active">
                  <div className="slide-29" />
                  <div className="hero clearfix">
                    <div className="col-sm-12">
                      <h3 className="new">CEO'S WORDS</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section id="special">
        <div className="container">
          <div id="anaimalfeed" className="row">
            <div className="environment_1 text-center clearfix">
              <div className="col-sm-12">
                <span className="col_1">
                  <i className="fa fa-bolt" />
                </span>
                <h3 className="newFont">WORDS FROM THE CHAIRMAN</h3>
              </div>
            </div>
            <div id="exchange" className="special_1 clearfix">
              <div className="ceo-image">
                <div className="special_1l newstylesection clearfix">
                  <img loading="lazy" src={ceo} className="iw" alt="ceo" />
                  <h3 className="col_1 mgt">Eng: Hassan AbdelAziz</h3>
                </div>
              </div>
              <div className="ceo-word">
                <div className="special_1r clearfix">
                 
                  <p className="ceopa">
                    We all live in a world full of challenges, continuous
                    technological development, and a constant quest to catch up
                    Development through science, knowledge, and competent and
                    capable human resources El Wady Cables Company has succeeded
                    in achieving this difficult equation since its inception in
                    2017.{" "}
                  </p>
                  <hr className="style14" />
                  <p className="ceopa">
                    We also realize the extent of the duties and
                    responsibilities we bear in achieving the highest level of
                    quality In the manufacture of electrical cables, we are keen
                    to mobilize all capabilities and components that help the
                    company El Wady Cables is committed to achieving the vision
                    and mission for which it was created, which is to achieve
                    quality and efficiency The most important and ultimate gain
                    that we all seek as a work team. We have always become
                    accustomed to the fertile valley of Egypt being the source
                    of giving and goodness for us and our children, and we have
                    learned from it to give with goodness Throughout history.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Ceo;
