import "../../Ceo/Ceo.css";
import "./CeoAr.css";

import ceo from "../../../assets/img/ceo.jpg";

const CeoAr = () => {
  return (
    <main className="ceo-ar">
      <div className="main clearfix">
        <div className="main_1 clearfix">
          <section id="center" className="center_home clearfix">
            <div
              className="carousel fade-carousel slide"
              data-ride="carousel"
              data-interval="4000"
              id="bs-carousel"
            >
              {/* Overlay */}
              <div className="overlay" />
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item slides active">
                  <div className="slide-29" />
                  <div className="hero clearfix">
                    <div className="col-sm-12">
                      <h3 className="new">مجــلس الأدارة </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section id="special">
        <div className="container">
          <div id="anaimalfeed" className="row">
            <div className="environment_1 text-center clearfix">
              <div className="col-sm-12">
                <span className="col_1">
                  <i className="fa fa-bolt" />
                </span>
                <h3 className="newFont">كلمة رئــيس مجلـس الأدارة</h3>
              </div>
            </div>
            <div id="exchange" className="special_1 clearfix">
              <div className="ceo-image">
                <div className="special_1l newstylesection clearfix">
                  <img loading="lazy" src={ceo} className="iw" alt="ceo" />
                  <h3 className="col_1 mgt">المهنـدس: حسـن عبدالعزيز</h3>
                </div>
              </div>
              <div className="ceo-word">
                <div className="special_1r clearfix">
                
                  <p>
                 نحن نعيش جميعنا في عالم ملئ بالتحديات والتطور التكنولوجي المستمر
                    والسعي الدائم للحاق بركب ذلك التطور من خلال العلم والمعرفة
                    والموارد البشرية ذات الكفاءة والقدرة ولقد نجحت شركة الوادي
                    للكابلات في تحقيق تلك المعادلة الصعبة منذ نشأتها عام 2017
                   .
                  </p>
                  <hr className="style14" />
                  <p>
                    كما أننا ندرك حجم الواجبات والمسئوليات التي نحملها على
                    عاتقنا في تحقيق أعلى مستوى من الجودة في صناعة الكابلات
                    الكهربائية ، لذا نحرص على حشد كافة الامكانيات والمقومات التي
                    تساعد شركة الوادي للكابلات في تحقيق الرؤية والرسالة التي
                    نشأت من أجلها وهي أن تحقيق الجودة والكفاءة المكسب الأهم
                    الأسمي الذي نسعى له جميعا كفريق عمل . لقد تعودنا دائما بأن
                    وادي مصر الخصيب هو منبع العطاء والخير لنا ولأبنائنا وتعلمنا
                    منه العطاء بالخير طوال الدهر ولذلك اتخذناه كأسم لشركتنا
                    لنكون مثله كمصدر للعطاء في مجال الصناعة ولعملائنا الكرام
                    وشركاء النجاح .{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CeoAr;
