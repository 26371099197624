import { useEffect, useState } from "react";
import axios from "axios";

import Slider from "./Slider";
import "./HomeSliderAr.css";




const HomeSliderAr = () => {
  const [sliders, setSliders] = useState([]);
  useEffect(() => {
    const getSliders = async () => {
      try {
        const { data } = await axios.get("/banner/viewAllBanners");
        if (data.status === "success") setSliders(data.banners);
      } catch (error) {
        console.error(error.message);
      }
    };
    getSliders();
  }, []);

  return (
    <div className="main clearfix home-slider-ar">
      <div className="main_1 clearfix">
        <section id="center" className="center_home clearfix">
          <div
            className="carousel fade-carousel slide"
            data-ride="carousel"
            data-interval="4000"
            id="bs-carousel"
          >
            {/* Overlay */}
            <div className="overlay" />
            {/* Indicators */}
            <ol className="carousel-indicators">
              {sliders.map((slider, idx) => (
                <li
                  key={idx}
                  data-target="#bs-carousel"
                  data-slide-to={`${idx}`}
                  className={idx === 0 ? "active" : ""}
                />
              ))}
            </ol>
            {/* Wrapper for slides */}
            <div className="carousel-inner">
              {sliders.map((slider, idx) => (
                <Slider
                  num={idx + 1}
                  image={
                    slider.photoContentType
                      ? `data:${slider.photoContentType};base64,${slider.photo}`
                      : slider.photo
                  }
                  label={slider.arDescription1}
                  description={slider.arDescription2}
                  key={slider.id}
                />
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomeSliderAr;
