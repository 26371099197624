import { Link } from "react-router-dom";

import "../../Home/Home.css";
import "./HomeAr.css";
import HomeSliderAr from "../../../components/ar/HomeSliderAr/HomeSliderAr";
import NewProductsAr from "../../../components/ar/NewProductsAr/NewProductsAr";

import videoSrc from "../../../assets/video.mp4";
import category1 from "../../../assets/img/h3.jpeg";
import category2 from "../../../assets/img/h1.jpeg";
import category3 from "../../../assets/img/h2.jpeg";

const Home = () => {
  return (
    <main className="home-ar">
      <HomeSliderAr />
      <section id="about_h">
        <div className="container">
          <div className="row">
            <div className="farm_1 text-center clearfix">
              <div className="col-sm-12">
                <h1 className="mgt">الجــودة تأتي أولا</h1>
              </div>
            </div>
            <div className="about_h_1 clearfix">
              <div className="col-sm-6">
                <div className="about_h_1l clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz">
                      <Link to="#">
                        <video
                          width="100%"
                          height="100%"
                          controls
                          autoPlay
                          muted
                        >
                          <source src={videoSrc} type="video/mp4"></source>
                          Your browser does not support the video tag.
                        </video>
                      </Link>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="about_h_1r clearfix">
                  <ul className="nav_1 mgt">
                    <li className="active">
                      <a data-toggle="tab" href="#home">
                        الشـركة{" "}
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#menu1">
                        الجــودة{" "}
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#menu2">
                        قـيم شـركتنا
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content clearfix">
                    <div id="home" className="tab-pane fade clearfix active in">
                      <div className="click clearfix">
                        <h4 className="mgt">
                          تتميز الوادي للكابلات بأعلى مستوى من التكنولوجيا
                        </h4>
                        <p>
                          {" "}
                          الـوادي للكابلات هي شركة متخصصة في تصنيع وتوزيع
                          الكابلات الكهربائية.
                          <br></br>
                          تأسست شركة الوادي للكابلات عام 2017، ويقع مقرها
                          الرئيسي بمدينة السادات الصناعية على مساحة تصنيع تزيد
                          عن 12 ألف متر مربع، بالإضافة إلى مكاتب التصميم
                          والمعامل ومناطق التخزين المرتبطة بها.
                        </p>
                      </div>
                    </div>
                    <div id="menu1" className="tab-pane fade clearfix">
                      <div className="click clearfix">
                        <h4 className="mgt">جـودة منتجاتنا</h4>
                        
                        <p> حاصلة على إعتماد الشركة القابضة لكهرباء مصر.</p>
                        <p> مستوفى نسبة المكون الصناعى المصرى.</p>
                        <p> حاصلة على إعتماد هيئة التنمية الصناعية.</p>
                        <p>
                          خاضعة للإختبارات لدى معامل المركز القومى لبحوث الإسكان
                          و البناء.
                        </p>
                      </div>
                    </div>
                    <div id="menu2" className="tab-pane fade clearfix">
                      <div className="click clearfix">
                        <h4 className="mgt">قـيم شـركتنا</h4>
                        <p className="small">
                          <strong>الشفافية والوضوح مع عملائنا:</strong> نحن نضمن
                          حصول عملائنا على معلومات واضحة وكاملة، وبناء الثقة
                          وتعزيز القرارات المستنيرة.
                        </p>
                        <p className="small">
                          <strong>الجودة هي الأساس</strong> لقد وضعنا أعلى
                          المعايير لكل جانب من جوانب منتجاتنا وخدماتنا.
                        </p>
                        <p className="small">
                          <strong>عملائنا هم هدفنا :</strong> نحن نتعاون مع
                          عملائنا، ونعزز بيئة داعمة ومستمرة للابد
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="farm">
        <div className="container">
          <div className="row">
            <div className="farm_1 text-center clearfix">
              <div className="col-sm-12">
                <h2 className="mgt">
                  <q>
                    نحن ملتزمون بمواصلة رحلتنا التي استمرت 7 سنوات في توفير
                    الطاقة والبيانات للناس
                  </q>
                </h2>
              </div>
            </div>
            <div className="farm_2 clearfix">
              <div className="col-sm-4">
                <div className="farm_2i text-center clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz">
                      <Link to="#">
                        <img loading="lazy" src={category1} className="iw" />
                      </Link>
                    </figure>
                  </div>
                  <h5>أكثر من 1500+ منتج متاح</h5>
                  <p className="figp">
                  كابلات الجهد المنخفض وكابلات الآبار والأسلاك والموصلات الهوائية المعزولة وموصلات ACSR  والكابلات الحريق
                  </p>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="farm_2i text-center clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz">
                      <Link to="#">
                        <img loading="lazy" src={category2} className="iw" />
                      </Link>
                    </figure>
                  </div>
                  <h5>أفضل خدمة ما بعد البيع</h5>
                  <p>
                    نحن نؤمن بأن كل عميل مهم وهدفنا الدائم هو دعم عملاءنا وكسب ثقتهم في أي وقت
                  </p>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="farm_2i text-center clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz">
                      <Link to="#">
                        <img loading="lazy" src={category3} className="iw" />
                      </Link>
                    </figure>
                  </div>
                  <h5>ملتزمون بالجودة</h5>
                  <p>
                    يرتكز هدف مجموعة الوادي للكابلات على تطوير أساس تكنولوجي
                    قوي، والتقدم المستمر، والحفاظ على الجودة
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="sale">
        <div className="sale_m clearfix">
          <div className="container">
            <div className="row">
              <div className="sale_1 clearfix">
                <div className="col-sm-12">
                  <h4 className="col">
                    <span className="col_1"></span> الـــوادي للكــابلات{" "}
                  </h4>
                  <p className="col_4">
                    شـــركة الوادي للكابلات هي إحدى الشركات المتخصصة في إنتاج
                    وتوريد الكابلات عالية الجودة.
                    <br></br> بفضل تاريخنا الغني والتزامنا القوي بالتميز، نجحنا
                    في ترسيخ أنفسنا كاسم موثوق به في صناعة الكابلات.
                  </p>
                  <h5>
                    <Link className="button" to={`/ar/story`}>
                      أعـرف أكثـر
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewProductsAr />

      <section id="benef">
        <div className="container">
          <div className="row award_1 stats">
            <div className="col-md-3">
              <div className="award_1i text-center">
                <span style={{ fontSize: 50 }} className="col_4">
                  <i className="fa fa-trophy" />
                </span>
                <h1 className="text-white mt-2 head14">6+</h1>
                <h5 className="col_3 mb-0">دول التصـدير</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div className="award_1i text-center">
                <span style={{ fontSize: 50 }} className="col_4">
                  <i className="fa fa-history" />
                </span>
                <h1 className="text-white mt-2 head14">7+</h1>
                <h5 className="col_3 mb-0">سنوات خبرة</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div className="award_1i text-center">
                <span style={{ fontSize: 50 }} className="col_4">
                  <i className="fa fa-product-hunt" />
                </span>
                <h1 className="text-white mt-2 head14">1,500+</h1>
                <h5 className="col_3 mb-0">منتـج</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div className="award_1i text-center">
                <span style={{ fontSize: 50 }} className="col_4">
                  <i className="fa fa-user" />
                </span>
                <h1 className="text-white mt-2 head14">1,000+</h1>
                <h5 className="col_3 mb-0">عـميل</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
