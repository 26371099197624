import { Fragment } from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Alert } from "reactstrap";

import Category from "./Category";
import "./Products.css";

function renderData(loading, errorMessage, data, itemPhotos) {
  if (loading) {
    return (
      <div className="fetching-data-spinner-parent">
        <div className="fetching-data-spinner"></div>
      </div>
    );
  } else {
    if (errorMessage) {
      return <Alert color="danger">{errorMessage}</Alert>;
    } else {
      return data.length > 0 ? (
        [...Array(Math.trunc(Math.ceil(data.length / 4))).keys()].map((num) => (
          <Fragment key={num}>
            <div className="row serv_2 cat-container">
              <RenderCategories
                curCategories={data.slice(
                  num * 4,
                  Math.min(num * 4 + 4, data.length)
                )}
                itemPhotos={itemPhotos}
              />
            </div>
            {num * 4 + 4 < data.length && (
              <>
                <br />
                <hr className="style14" />
                <br />
              </>
            )}
          </Fragment>
        ))
      ) : (
        <Alert color="warning">No Categories found</Alert>
      );
    }
  }
}

const Products = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [categories, setCategories] = useState([]);
  const [categoryPhotos, setCategoryPhotos] = useState({});
  const cache = useRef({});

  useEffect(() => {
    const getCategory = async (id) => {
      const url = `category/getCategoryById/${id}`;
      if (cache.current[url]) {
        const data = cache.current[url];
        setCategoryPhotos((curPhotos) => {
          return { ...curPhotos, [id]: data };
        });
      } else {
        try {
          const { data } = await axios.get(url);
          if (!data.error) {
            cache.current[url] = data.category;
            setCategoryPhotos((curPhotos) => {
              return { ...curPhotos, [id]: data.category };
            });
          }
        } catch (error) {
          console.error(error.message);
        }
      }
    };
    const getCategories = async () => {
      const url = "category/viewAllCategories";
      setErrorMessage(undefined);
      setLoading(true);
      if (cache.current[url]) {
        const data = cache.current[url];
        setCategories(data);
        setLoading(false);
        data.forEach((item) => {
          getCategory(item.id);
        });
      } else {
        try {
          const { data } = await axios.get("category/viewAllCategories");
          if (data.error) {
            if (data.error.includes("REP")) setCategories([]);
            else setErrorMessage(data.error);
            setLoading(false);
          } else {
            cache.current[url] = data.gallery;
            setCategories(data.categories);
            setLoading(false);
            data.categories.forEach((item) => {
              getCategory(item.id);
            });
          }
        } catch (error) {
          setErrorMessage(error.message);
          setLoading(false);
        }
      }
    };

    getCategories();
  }, []);

  return (
    <main>
      <section id="serv">
        <div className="container">
          <div className="row serv_1 text-center mb-3">
            <div className="col-md-12">
              <h5 className="col_1">Categories</h5>
              <h2 className="mb-0">Products</h2>
              <span style={{ fontSize: 40 }}>
                <i className="fa fa-electric col_4" />
              </span>
            </div>
          </div>
          {renderData(loading, errorMessage, categories, categoryPhotos)}
        </div>
      </section>
    </main>
  );
};

function RenderCategories({ curCategories, itemPhotos }) {
  return curCategories.map((category) => (
    <Category
      id={category.id}
      image={
        itemPhotos[category.id]?.photoContentType
          ? `data:${itemPhotos[category.id].photoContentType};base64,${
              itemPhotos[category.id].photo
            }`
          : ""
      }
      name={category.name}
      key={category.id}
    />
  ));
}

export default Products;
