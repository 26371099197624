import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Link } from "react-router-dom";

import "./PaginationComp.css";

const PaginationComp = ({
  to,
  activePage,
  totalItems,
  itemsPerPage,
  otherParams,
  attr,
}) => {
  const pagesCount = Math.trunc(Math.ceil(totalItems / itemsPerPage));
  let textParams = "";
  for (let key in otherParams) {
    if (otherParams[key]) {
      textParams += `&${key}=${otherParams[key]}`;
    }
  }

  return pagesCount ? (
    <div className="pagination" {...attr}>
      <Pagination aria-label="Page navigation">
        <PaginationItem disabled={activePage === 1}>
          <PaginationLink tag={Link} to={`${to}?page=1${textParams}`}>
            <i className="fa fa-angle-double-left"></i>
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={activePage === 1}>
          <PaginationLink
            tag={Link}
            to={`${to}?page=${
              activePage === 1 ? activePage : activePage - 1
            }${textParams}`}
          >
            <i className="fa fa-angle-left"></i>
          </PaginationLink>
        </PaginationItem>
        {[...Array(pagesCount).keys()].map((pageNum) => (
          <PaginationItem active={activePage === pageNum + 1} key={pageNum}>
            <PaginationLink
              tag={Link}
              to={`${to}?page=${pageNum + 1}${textParams}`}
            >
              {pageNum + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={activePage === pagesCount}>
          <PaginationLink
            tag={Link}
            to={`${to}?page=${
              activePage === pagesCount ? activePage : activePage + 1
            }${textParams}`}
          >
            <i className="fa fa-angle-right"></i>
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={activePage === pagesCount}>
          <PaginationLink
            tag={Link}
            to={`${to}?page=${pagesCount}${textParams}`}
          >
            <i className="fa fa-angle-double-right"></i>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  ) : (
    <></>
  );
};

export default PaginationComp;
