/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

import Modal from "../../components/Modal/Modal";
import g1 from "../../assets/img/v1.jpeg";
import g2 from "../../assets/img/v2.jpeg";
import g3 from "../../assets/img/v3.jpeg";
import myVideo from "../../assets/video.mp4";
import "./VGallery.css";

const VGallery = () => {
  const [modalData, setModalData] = useState({
    showModal: false,
    media: "",
  });

  return (
    <main>
      <Modal
        showModal={modalData.showModal}
        type="video"
        setModalData={setModalData}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src={modalData.media}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            style={{
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%",
            }}
          ></iframe>
        </div>
        {/* <video
          width="100%"
          height="100%"
          controls
          autoPlay
          muted
          key={modalData.media}
        >
          <source src={modalData.media} type="video/mp4"></source>
          Your browser does not support the video tag.
        </video> */}
      </Modal>

      <div className="body_section gallary_home">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sec_title center_view">
                <span className="float_text animate-box">Gallery</span>
                <h3>Video Gallery</h3>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-11 vgallery-video">
              <div
                className="pop_img pop_video"
                onClick={() => {
                  setModalData({
                    showModal: true,
                    media:
                      "https://www.youtube.com/embed/naKCk9ln6qA?si=5OsFsuEUuJvxY2G1",
                  });
                }}
              >
                <img loading="lazy" src={g1} alt="" />
                <h4 style={{ color: "#111314", fontWeight: "bold" }}>
                  El Wady Cables
                </h4>
                <a
                  className="play_vid"
                  data-fancybox="watermrk"
                  data-caption="caption #1"
                >
                  <FontAwesomeIcon icon={faPlay} color="#111314" />
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-11 vgallery-video">
              <div
                className="pop_img pop_video"
                onClick={() => {
                  setModalData({
                    showModal: true,
                    media:
                      "https://www.youtube.com/embed/BgQnGWwfmfU?si=PiWvLPar3z50Ky9s",
                  });
                }}
              >
                <img loading="lazy" src={g2} alt="" />
                <h4 style={{ color: "#111314", fontWeight: "bold" }}>
                  El Wady Cables
                </h4>
                <a
                  className="play_vid"
                  data-fancybox="watermrk"
                  data-caption="caption #1"
                >
                  <FontAwesomeIcon icon={faPlay} color="#111314" />
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-11 vgallery-video">
              <div
                className="pop_img pop_video"
                onClick={() => {
                  setModalData({
                    showModal: true,
                    media:
                      "https://www.youtube.com/embed/JRuBUeD2y7Y?si=dNeQq-2G-4Sl0eMw",
                  });
                }}
              >
                <img loading="lazy" src={g3} alt="" />
                <h4 style={{ color: "#111314", fontWeight: "bold" }}>
                  El Wady Cables
                </h4>
                <a
                  className="play_vid"
                  data-fancybox="watermrk"
                  data-caption="caption #1"
                >
                  <FontAwesomeIcon icon={faPlay} color="#111314" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default VGallery;
