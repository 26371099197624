import { Link } from "react-router-dom";

import "./Home.css";
import HomeSlider from "../../components/HomeSlider/HomeSlider";
import NewProducts from "../../components/NewProducts/NewProducts";

import videoSrc from "../../assets/video.mp4";
import category1 from "../../assets/img/h3.jpeg";
import category2 from "../../assets/img/h1.jpeg";
import category3 from "../../assets/img/h2.jpeg";

const Home = () => {
  return (
    <main>
      <HomeSlider />
      <section id="about_h">
        <div className="container">
          <div className="row">
            <div className="farm_1 text-center clearfix">
              <div className="col-sm-12">
                <h1 className="mgt">Quality Comes First</h1>
              </div>
            </div>
            <div className="about_h_1 clearfix">
              <div className="col-sm-6">
                <div className="about_h_1l clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz">
                      <Link to="#">
                        <video
                          width="100%"
                          height="100%"
                          controls
                          autoPlay
                          muted
                        >
                          <source src={videoSrc} type="video/mp4"></source>
                          Your browser does not support the video tag.
                        </video>
                      </Link>
                    </figure>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="about_h_1r clearfix">
                  <ul className="nav_1 mgt">
                    <li className="active">
                      <a data-toggle="tab" href="#home">
                        Company{" "}
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#menu1">
                        Quality{" "}
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#menu2">
                        Values
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content clearfix">
                    <div id="home" className="tab-pane fade clearfix active in">
                      <div className="click clearfix">
                        <h4 className="title mgt">
                          El wady Cables have Highest Level Technology
                        </h4>
                        <p>
                          {" "}
                          El Wady Cables is a company specialized in
                          manufacturing and distributing electrical cables.
                          <br></br>
                          The company was established in 2017, and its
                          headquarters are located in Sadat Industrial City on a
                          manufacturing area of ​​​​more than 12 thousand square
                          meters, in addition to design offices, laboratories
                          and associated storage areas.
                        </p>
                      </div>
                    </div>
                    <div id="menu1" className="tab-pane fade clearfix">
                      <div className="click clearfix">
                        <h4 className="title mgt">Products Quality</h4>
                      
                        <p>
                          Certified by the Egyptian Electricity Holding Company.
                        </p>
                        <p>Meet the Egyptian industrial component.</p>
                        <p>
                          {" "}
                          Approved by the Industrial Development Authority.
                        </p>
                        <p>
                          Subject to tests at the laboratories of the National
                          Center for Housing and Building Research.
                        </p>
                      </div>
                    </div>
                    <div id="menu2" className="tab-pane fade clearfix">
                      <div className="click clearfix">
                        <h4 className="mgt">Values</h4>
                        <p className="small">
                          <strong>
                            Transparency and clarity with our clients:
                          </strong>{" "}
                          We ensure that our clients receive clear and complete
                          information, building trust and promoting informed
                          decisions.
                        </p>
                        <p className="small">
                          <strong>Quality is the foundation:</strong>We set the
                          highest standards for every aspect of our products and
                          services.
                        </p>
                        <p className="small">
                          <strong>Our customers are our goal: :</strong>We
                          collaborate with our customers, fostering a supportive
                          and lasting environment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="farm">
        <div className="container">
          <div className="row">
            <div className="farm_1 text-center clearfix">
              <div className="col-sm-12">
                <h2 className="mgt">
                  <q>
                    We Are Devoted To Continuing Our 7-Year Journey In
                    Delivering Energy And Data To People
                  </q>
                </h2>
              </div>
            </div>
            <div className="farm_2 clearfix">
              <div className="col-sm-4">
                <div className="farm_2i text-center clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz">
                      <Link to="#">
                        <img loading="lazy" src={category1} className="iw" />
                      </Link>
                    </figure>
                  </div>
                  <h5>Over 1500+ products available</h5>
                  <p className="figp">
                    Flat cables, wires, low voltage cables and solid core
                    aluminum conductors<q>ACSR</q>.
                  </p>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="farm_2i text-center clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz">
                      <Link to="#">
                        <img loading="lazy" src={category2} className="iw" />
                      </Link>
                    </figure>
                  </div>
                  <h5>The best after sales service</h5>
                  <p>
                  Our constant goal is to support our customers and gain their trust at any time
                  </p>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="farm_2i text-center clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz">
                      <Link to="#">
                        <img loading="lazy" src={category3} className="iw" />
                      </Link>
                    </figure>
                  </div>
                  <h5>Committed to Quality</h5>
                  <p>
                    El Wady Cables Group's goal is to develop a strong
                    technological foundation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="sale">
        <div className="sale_m clearfix">
          <div className="container">
            <div className="row">
              <div className="sale_1 clearfix">
                <div className="col-sm-12">
                  <h4 className="col">
                    <span className="col_1"></span> El Wady Cables{" "}
                  </h4>
                  <p className="col_4 changepa">
                    El Wady Cables Company is one of the companies specialized
                    in producing and supplying high-quality cables.
                    <br></br> With a rich history and a strong commitment to
                    excellence, we have established ourselves as a trusted name
                    in the cable industry.
                  </p>
                  <h5>
                    <Link className="button" to={`/story/`}>
                      READ MORE
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <NewProducts />

      <section id="benef">
        <div className="container">
          <div className="row award_1 stats">
            <div className="col-md-3">
              <div className="award_1i text-center">
                <span style={{ fontSize: 50 }} className="col_4">
                  <i className="fa fa-user" />
                </span>
                <h1 className="text-white mt-2 head14">1,000+</h1>
                <h5 className="col_3 mb-0">Customers</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div className="award_1i text-center">
                <span style={{ fontSize: 50 }} className="col_4">
                  <i className="fa fa-product-hunt" />
                </span>
                <h1 className="text-white mt-2 head14">1,500+</h1>
                <h5 className="col_3 mb-0">Products</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div className="award_1i text-center">
                <span style={{ fontSize: 50 }} className="col_4">
                  <i className="fa fa-history" />
                </span>
                <h1 className="text-white mt-2 head14">7+</h1>
                <h5 className="col_3 mb-0">Experience</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div className="award_1i text-center">
                <span style={{ fontSize: 50 }} className="col_4">
                  <i className="fa fa-trophy" />
                </span>
                <h1 className="text-white mt-2 head14">6+</h1>
                <h5 className="col_3 mb-0">Export Countries</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
