import { Link } from "react-router-dom";

import "./Story.css";

import about4 from "../../assets/img/firstory.jpg";
import cowchange from "../../assets/img/IMG_4810-_1_-_1_-_1_.jpg";

const Story = () => {
  return (
    <main>
      <div className="main clearfix">
        <div className="main_1 clearfix">
          <section id="center" className="center_home clearfix">
            <div
              className="carousel fade-carousel slide"
              data-ride="carousel"
              data-interval="4000"
              id="bs-carousel"
            >
              {/* Overlay */}
              <div className="overlay" />
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item slides active">
                  <div className="slide-31" />
                  <div className="hero clearfix">
                    <div className="col-sm-12">
                      <h3 className="new">OUR STORY </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <section id="inform">
        <div className="container">
          <div className="row">
            <div className="inform_1 clearfix">
              <div className="col-sm-6">
                <div className="inform_1l clearfix">
                  <h2 className="col_1 mgt aboutheadtitle">El Wady Cables</h2>
                  <p className="newstyle">
                    El Wady Cables Company was established in 2017, and its
                    headquarters are in El-Sadat Industrial City on an area of
                    over 12,000 square meters of manufacturing space, in
                    addition to design offices, laboratories, and associated
                    storage area. Our company specializes in the manufacturing
                    and distribution of electrical cables.
                  </p>
                  <br />
                  <p className="newstyle">
                    El Wady Cables Company is one of the companies specialized
                    in producing and supplying high-quality cables. With a rich
                    history and a strong commitment to excellence, we have
                    established ourselves as a trusted name in the cable
                    industry. Our extensive product portfolio meets the needs of
                    various sectors including power, construction, cars, and
                    others.
                  </p>
                  <br />
                  <p className="newstyle">
                    We strongly believe in innovation and advanced technology.
                    We continuously invest in research and development to stay
                    at the forefront of the industry and provide our customers
                    with the latest advancements in cable technology.
                  </p>
                  <br />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="inform_1r clearfix">
                  <div className="inform_1ri clearfix story-img">
                    <img loading="lazy" src={about4} alt="about" className="iw" />
                  </div>
                  <div className="inform_1ri1 clearfix">
                    <h4 className="col mgt">What are you waiting for ?</h4>
                    <h5>
                      <Link className="newbutton" to={`/contact`}>
                        Request A Quote
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="center_2" className="clearfix">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center clearfix">
              <h1 className="mgt">Our Mission & Vision</h1>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="center_1_main clearfix">
              <div className="col-sm-4 center_1_left clearfix">
                <div className="center_1_left_inner clearfix">
                  <h6 className="mgt">
                    <i className="fa fa-bullseye" />
                  </h6>
                  <h4>
                    <Link to="#">Our Mission</Link>
                  </h4>
                  <p>
                    Credibility, clarity and commitment with our customers are
                    our real capital.
                  </p>
                </div>
              </div>
              <div className="col-sm-4 center_1_left clearfix">
                <div className="center_1_left_inner clearfix">
                  <h6 className="mgt">
                    <i className="fa fa-eye" />
                  </h6>
                  <h4>
                    <Link to="#">Our Vision </Link>
                  </h4>
                  <p>
                    We seek to be the leading company in the cable and energy
                    industry of the future.
                  </p>
                </div>
              </div>
              <div className="col-sm-4 center_1_left clearfix">
                <div className="center_1_left_inner clearfix">
                  <h6 className="mgt">
                    <i className="fa fa-key" />
                  </h6>
                  <h4>
                    <Link to="#">Our Values</Link>
                  </h4>
                  <ul className="classic">
                    <li>Transparency and clarity.</li>
                    <li>Quality is the foundation.</li>
                    <li>Our customers are our goal.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="off">
        <div className="container">
          <div className="row off1">
            <div className="col-md-6">
              <div className="off1l">
                <div className="grid clearfix">
                  <figure className="effect-jazz mainimage mb-0">
                    <Link to="#">
                      <img loading="lazy" src={cowchange} className="w-100" alt="cows" />
                    </Link>
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="off1r">
                <h5 className="col_1">
                  El Wady Cables means excellence & innovation
                </h5>
                <h2>Our Commitment</h2>
                <span style={{ fontSize: 40 }}>
                  <i className="fa fa-bolt col_4" />
                </span>
                <p className="mt-3">
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  Ensuring that the necessary competent personnel.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  Report & investigate all the unsafe acts.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  Provide a safe and healthy environment.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  Comply with all applicable legal statutory and regulatory.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  Setting, monitoring and periodically reviewing the quality.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  Provision of appropriate resources for effective
                  implementation.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  Eliminate the hazards and reduce the risks related to quality.
                </p>
                <p>
                  <i style={{ marginRight: 5 }} className="fa fa-check col_1" />
                  Integration of QHSE considerations into our business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Story;
