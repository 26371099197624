/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import "./Header.css";

import mainlogo from "../../assets/img/enLogo.png";
import Language from "../Language/Language";

const Header = () => {
  const location = useLocation();

  useEffect(() => {
    $(document).ready(function () {
      /*****Fixed Menu******/
      var secondaryNav = $(".cd-secondary-nav"),
        secondaryNavTopPosition = secondaryNav.offset().top,
        navbar_height = document.querySelector(".navbar").offsetHeight;

      $(window).on("scroll", function () {
        if ($(window).scrollTop() > secondaryNavTopPosition + navbar_height) {
          secondaryNav.addClass("is-fixed");
          document.body.style.paddingTop = navbar_height + "px";
        } else {
          secondaryNav.removeClass("is-fixed");
          document.body.style.paddingTop = "0";
        }
      });
    });
  }, []);

  return (
    <>
      <section id="top">
        <div className="container">
          <div className="row">
            <div className="top_1 clearfix">
              <div className="col-sm-4">
                <div className="top_1m text-center clearfix">
                  <h3 className="mgt">
                    <a href="/#/" className="logo-container">
                      <img loading="lazy" className="logo" alt="abc" src={mainlogo} />
                    </a>
                  </h3>
                </div>
              </div>
              <div className="col-sm-5">
                <div className="top_1l clearfix">
                  <p className="mgt font_16 text-center">
                    <i className="fa fa-map col_1" />
                    <span className="col_6">Headquarters :</span>ElSheikh Zayed, Beverly Hills, Bolygon Zone
                  </p>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="top_1r text-right clearfix">
                  <h6 className="big mgt">
                    <Link className="button mgt" to="/contact">
                      <i className="fa fa-envelope-o" /> Get in Touch
                    </Link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="header" className="clearfix cd-secondary-nav header-en">
        <nav className="navbar nav_t">
          <div className="container">
            <div className="navbar-header page-scroll">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="navbar-brand logo-container" href="/#/">
                <img loading="lazy" alt="abc" src={mainlogo} />
              </a>
            </div>
            {/* Brand and toggle get grouped for better mobile display */}
            {/* Collect the nav links, forms, and other content for toggling */}
            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav">
                <li>
                  <Link
                    to="/"
                    className={`m_tag ${
                      location.pathname === "/" ? "active_tab" : ""
                    }`}
                  >
                    Home
                  </Link>
                </li>
                <li className="dropdown">
                  <a
                    className={`m_tag ${
                      location.pathname.startsWith("/story") ||
                      location.pathname.startsWith("/history") ||
                      location.pathname.startsWith("/ceo") ||
                      location.pathname.startsWith("/certificate") ||
                      location.pathname.startsWith("/our-team")
                        ? "active_tab"
                        : ""
                    }`}
                    href="#"
                    data-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    About us
                    <span className="caret" />
                  </a>
                  <ul className="dropdown-menu drop_3 about-menu" role="menu">
                    <li>
                      <Link to="/story">OUR STORY</Link>
                    </li>
                    <li>
                      <Link to="/story/#center_2" className="border_none">
                        MISSION & VISSION
                      </Link>
                    </li>
                    <li>
                      <Link to="/history" className="border_none">
                         CHALLENGES
                      </Link>
                    </li>

                    <li>
                      <Link to="/ceo" className="border_none">
                        WORDS FROM THE CHAIRMAN
                      </Link>
                    </li>

                    <li>
                      <Link to="/certificate" className="border_none">
                         CERTIFICATES
                      </Link>
                    </li>

                   {/* <li>
                      <Link to="/our-team" className="border_none">
                        OUR TEAM
                      </Link>
                    </li> */}
                  </ul>
                </li>

                <li>
                  <Link
                    to="products"
                    className={`m_tag ${
                      location.pathname === "/products" ? "active_tab" : ""
                    }`}
                  >
                    Products
                  </Link>
                </li>
                <li className="dropdown">
                  <a
                    className={`m_tag ${
                      location.pathname.startsWith("/gallery") ||
                      location.pathname.startsWith("/vgallery")
                        ? "active_tab"
                        : ""
                    }`}
                    href="#"
                    data-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    Gallery
                    <span className="caret" />
                  </a>
                  <ul className="dropdown-menu drop_3" role="menu">
                    <li>
                      <Link to="/gallery" className="border_none">
                        PHOTOS
                      </Link>
                    </li>

                    <li>
                      <Link to="/vgallery" className="border_none">
                        VIDEOS
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link
                    to="/contact"
                    className={`m_tag ${
                      location.pathname === "/contact" ? "active_tab" : ""
                    }`}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
              <ul className="nav navbar-nav navbar-right">
                {/* ------------------ Language icon ------------------------ */}
                <Language location={location} />
              </ul>
            </div>
            {/* /.navbar-collapse */}
          </div>
          {/* /.container-fluid */}
        </nav>
      </section>
    </>
  );
};

export default Header;
