import { Alert } from "reactstrap";

import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <div>
      <Alert color="danger">This page does not exist.</Alert>
    </div>
  );
};

export default PageNotFound;
