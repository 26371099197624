/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Language.css";

import EgyptFlag from "../../assets/img/egypt-flag-icon.png";
import UsFlag from "../../assets/img/united-states-flag-icon.png";

const Language = ({ location }) => {
  return (
    <li className="language">
      <a
        href="#"
        style={{
          display: location.pathname.startsWith("/ar")
            ? "none"
            : "inline-block",
        }}
        title="Arabic"
        onClick={(e) => {
          e.preventDefault();
          window.location.hash = `#/ar${
            location.pathname === "/" ? "" : location.pathname
          }${location.hash}${location.search}`;
          window.location.reload();
        }}
      >
        <img loading="lazy" src={EgyptFlag} alt="Ar" />
      </a>
      <a
        href={`/`}
        style={{
          display: location.pathname.startsWith("/ar")
            ? "inline-block"
            : "none",
        }}
        title="English"
        onClick={(e) => {
          e.preventDefault();
          window.location.hash = `#/${location.pathname.slice(4)}${
            location.hash
          }${location.search}`;
          window.location.reload();
        }}
      >
        <img loading="lazy" src={UsFlag} alt="En" />
      </a>
    </li>
  );
};

export default Language;
