import { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faDownload } from "@fortawesome/free-solid-svg-icons";
import PaginationComp from "../../../components/Pagination/PaginationComp";
import { Product_ITEMS_PER_PAGE } from "../../../constants/SharedConstants";
import "../../Product/Product.css";
import "./ProductAr.css";

function renderProducts(loading, errorMessage, data, itemPhotos) {
  if (loading) {
    return (
      <div className="item-loading">
        <div className="fetching-data-spinner-parent">
          <div className="fetching-data-spinner"></div>
        </div>
      </div>
    );
  } else {
    if (errorMessage) {
      return (
        <div className="item-alert">
          <Alert color="danger">{errorMessage}</Alert>
          <div className="cart-btn">
            <Link className="button" to="/products">
              back
            </Link>
          </div>
          ;
        </div>
      );
    } else {
      return data.length > 0 ? (
        <div className="products">
          {data.map((item) => {
            return (
              <div className="item">
                <div className="image">
                  <img
                    loading="lazy"
                    src={
                      itemPhotos[item.id]?.photoContentType
                        ? `data:${
                            itemPhotos[item.id].photoContentType
                          };base64,${itemPhotos[item.id].photo}`
                        : ""
                    }
                    alt={item.name}
                  />
                </div>
                <span>{item.name}</span>
                <a
                  href={
                    itemPhotos[item.id]?.pdfContentType
                      ? `data:${itemPhotos[item.id].pdfContentType};base64,${
                          itemPhotos[item.id].pdfFile
                        }`
                      : ""
                  }
                  download={`${item.name}.pdf`}
                >
                  <FontAwesomeIcon icon={faDownload} className="icon" />
                  Datasheet
                </a>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="item-alert">
          <Alert color="warning">No Items found</Alert>
        </div>
      );
    }
  }
}

const Product = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [categoryItems, setCategoryItems] = useState([]);
  const [categoryItemPhotos, setCategoryItemPhotos] = useState({});
  const [categoryData, setCategoryData] = useState({
    name: "",
    image: "",
  });
  const [paginationState, setPaginationState] = useState({
    activePage: 1,
    totalItems: 0,
    itemsPerPage: Product_ITEMS_PER_PAGE,
  });
  const filterRef = useRef(null);
  const cache = useRef({});

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getCategoryName = async () => {
      try {
        const { data } = await axios.get(`category/getCategoryById/${id}`);
        if (!data.error) {
          setCategoryData({
            name: data.category.arName,
            image: `data:${data.category.photoContentType};base64,${data.category.photo}`,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    getCategoryName();
  }, [id]);

  function getItemParamsFromSearch() {
    const params = new URLSearchParams(location.search);
    const conductorMetal = params.get("conductorMetal");
    const voltageGrade = params.get("voltageGrade");
    const noOfCores = params.get("noOfCores");
    const metallicSheathing = params.get("metallicSheathing");
    const armouring = params.get("armouring");
    const insulation = params.get("insulation");
    return {
      conductorMetal,
      voltageGrade,
      noOfCores,
      metallicSheathing,
      armouring,
      insulation,
    };
  }
  const getItem = async (id) => {
    const url = `/items/getItemById/${id}`;
    if (cache.current[url]) {
      const data = cache.current[url];
      setCategoryItemPhotos((curPhotos) => {
        return { ...curPhotos, [id]: data };
      });
    } else {
      try {
        const { data } = await axios.get(url);
        if (!data.error) {
          cache.current[url] = data.item;
          setCategoryItemPhotos((curPhotos) => {
            return { ...curPhotos, [id]: data.item };
          });
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };
  const getCategoryItems = async (page, size, filterParams, itemPhotos) => {
    try {
      setLoading(true);
      setErrorMessage(undefined);
      const { data } = await axios.post(
        `items/itemFilter`,
        {
          categoryId: id,
          ...filterParams,
        },
        {
          params: {
            page: page - 1,
            size: size,
            sortBy: "id",
          },
        }
      );
      if (data.error) {
        if (data.error.includes("REP")) setCategoryItems([]);
        else setErrorMessage(data.error);
        setLoading(false);
      } else {
        const totalItems = data.total;
        setPaginationState({
          activePage: page,
          totalItems,
          itemsPerPage: size,
        });
        setCategoryItems(data.items);
        setLoading(false);
        data.items.forEach((item) => {
          getItem(item.id);
        });
      }
    } catch (error) {
      setErrorMessage(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let page = params.get("page");
    if (page && !Number.isNaN(Number(page))) page = Number(page);
    else page = null;

    if (page) {
      let itemParams = getItemParamsFromSearch();
      for (let key in itemParams) {
        if (!itemParams[key]) delete itemParams[key];
      }
      getCategoryItems(page, Product_ITEMS_PER_PAGE, itemParams);
    } else {
      navigate(`/ar/products`);
    }
  }, [id, location.search]);

  function onFilterItemChecked(e) {
    const params = new URLSearchParams(location.search),
      page = params.get("page");
    let textParams = `?page=${page}`;

    const itemParams = getItemParamsFromSearch();
    for (let key in itemParams) {
      if (key === e.target.name) {
        if (e.target.checked)
          textParams += `&${e.target.name}=${e.target.value}`;
      } else if (itemParams[key] && itemParams[key] !== "null") {
        textParams += `&${key}=${itemParams[key]}`;
      }
    }

    navigate(`/products/${id}/category-items${textParams}`);
  }

  return (
    <main style={{ paddingBottom: "30px" }} className="product-ar">
      <div className="main clearfix" style={{ marginBottom: "0" }}>
        <div className="main_1 clearfix">
          <section id="center" className="center_home clearfix">
            <div
              className="carousel fade-carousel slide"
              data-ride="carousel"
              data-interval="4000"
              id="bs-carousel"
            >
              {/* Overlay */}
              <div className="overlay" />
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item slides active">
                  <div
                    className="slide-27"
                    style={{
                      backgroundImage: `url(${categoryData.image})`,
                    }}
                  />
                  <div className="hero clearfix">
                    <div className="col-sm-12">
                      <h3 className="news categoryname">{categoryData.name}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="category-items">
        <div
          className="filter-icon"
          onClick={(e) => {
            filterRef.current.classList.toggle("show");
          }}
        >
          filter <FontAwesomeIcon icon={faAngleDown} className="icon" />
        </div>
        <div className="container">
          <div className="filter" disabled={loading} ref={filterRef}>
            <div className="box">
              <span>Conductor Metal</span>
              <div className="choices">
                <div className="choice">
                  <input
                    id="cm1"
                    type="checkbox"
                    name="conductorMetal"
                    value="Aluminum Cables"
                    onChange={onFilterItemChecked}
                    checked={
                      new URLSearchParams(location.search).get(
                        "conductorMetal"
                      ) === "Aluminum Cables"
                    }
                  />
                  <label htmlFor="cm1">Aluminum Cables</label>
                </div>
                <div className="choice">
                  <input
                    id="cm2"
                    type="checkbox"
                    name="conductorMetal"
                    value="Copper Cables"
                    onChange={onFilterItemChecked}
                    checked={
                      new URLSearchParams(location.search).get(
                        "conductorMetal"
                      ) === "Copper Cables"
                    }
                  />
                  <label htmlFor="cm2">Copper Cables</label>
                </div>
                <div className="choice">
                  <input
                    id="cm3"
                    type="checkbox"
                    name="conductorMetal"
                    value="Copper Conductors"
                    onChange={onFilterItemChecked}
                    checked={
                      new URLSearchParams(location.search).get(
                        "conductorMetal"
                      ) === "Copper Conductors"
                    }
                  />
                  <label htmlFor="cm3">Copper Conductors</label>
                </div>
                <div className="choice">
                  <input
                    id="cm4"
                    type="checkbox"
                    name="conductorMetal"
                    value="Steel Conductors"
                    onChange={onFilterItemChecked}
                    checked={
                      new URLSearchParams(location.search).get(
                        "conductorMetal"
                      ) === "Steel Conductors"
                    }
                  />
                  <label htmlFor="cm4">Steel Conductors</label>
                </div>
                <div className="choice">
                  <input
                    id="cm5"
                    type="checkbox"
                    name="conductorMetal"
                    value="Aluminum Conductors"
                    onChange={onFilterItemChecked}
                    checked={
                      new URLSearchParams(location.search).get(
                        "conductorMetal"
                      ) === "Aluminum Conductors"
                    }
                  />
                  <label htmlFor="cm5">Aluminum Conductors</label>
                </div>
                <div className="choice">
                  <input
                    id="cm6"
                    type="checkbox"
                    name="conductorMetal"
                    value="Aluminum Steel Conductors"
                    onChange={onFilterItemChecked}
                    checked={
                      new URLSearchParams(location.search).get(
                        "conductorMetal"
                      ) === "Aluminum Steel Conductors"
                    }
                  />
                  <label htmlFor="cm6">Aluminum Steel Conductors</label>
                </div>
              </div>
            </div>
            <div className="box">
              <div>
                <span>Voltage Grade</span>
                <div className="choices">
                  <div className="choice">
                    <input
                      id="vg1"
                      type="checkbox"
                      name="voltageGrade"
                      value="0.6/1 KV"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "voltageGrade"
                        ) === "0.6/1 KV"
                      }
                    />
                    <label htmlFor="vg1">0.6/1 KV</label>
                  </div>
                  <div className="choice">
                    <input
                      id="vg2"
                      type="checkbox"
                      name="voltageGrade"
                      value="450/750 V"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "voltageGrade"
                        ) === "450/750 V"
                      }
                    />
                    <label htmlFor="vg2">450/750 V</label>
                  </div>
                  <div className="choice">
                    <input
                      id="vg3"
                      type="checkbox"
                      name="voltageGrade"
                      value="300/500 V"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "voltageGrade"
                        ) === "300/500 V"
                      }
                    />
                    <label htmlFor="vg3">300/500 V</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div>
                <span>No of Cores</span>
                <div className="choices">
                  <div className="choice">
                    <input
                      id="noc1"
                      type="checkbox"
                      name="noOfCores"
                      value="Multi-Core (>5)"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "noOfCores"
                        ) === "Multi-Core (>5)"
                      }
                    />
                    <label htmlFor="noc1">Multi-Core (&gt;5)</label>
                  </div>
                  <div className="choice">
                    <input
                      id="noc2"
                      type="checkbox"
                      name="noOfCores"
                      value="Multi-Core (<=5)"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "noOfCores"
                        ) === "Multi-Core (<=5)"
                      }
                    />
                    <label htmlFor="noc2">Multi-Core (&le;5)</label>
                  </div>
                  <div className="choice">
                    <input
                      id="noc3"
                      type="checkbox"
                      name="noOfCores"
                      value="Single-Core"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "noOfCores"
                        ) === "Single-Core"
                      }
                    />
                    <label htmlFor="noc3">Single-Core</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div>
                <span>Metallic Sheathing/Screening</span>
                <div className="choices">
                  <div className="choice">
                    <input
                      id="ms1"
                      type="checkbox"
                      name="metallicSheathing"
                      value="Lead Alloy Sheath"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "metallicSheathing"
                        ) === "Lead Alloy Sheath"
                      }
                    />
                    <label htmlFor="ms1">Lead Alloy Sheath</label>
                  </div>
                  <div className="choice">
                    <input
                      id="ms2"
                      type="checkbox"
                      name="metallicSheathing"
                      value="Tape Screened"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "metallicSheathing"
                        ) === "Tape Screened"
                      }
                    />
                    <label htmlFor="ms2">Tape Screened</label>
                  </div>
                  <div className="choice">
                    <input
                      id="ms3"
                      type="checkbox"
                      name="metallicSheathing"
                      value="Un Sheathed"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "metallicSheathing"
                        ) === "Un Sheathed"
                      }
                    />
                    <label htmlFor="ms3">Un Sheathed</label>
                  </div>
                  <div className="choice">
                    <input
                      id="ms4"
                      type="checkbox"
                      name="metallicSheathing"
                      value="Wire Screened"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "metallicSheathing"
                        ) === "Wire Screened"
                      }
                    />
                    <label htmlFor="ms4">Wire Screened</label>
                  </div>
                  <div className="choice">
                    <input
                      id="ms5"
                      type="checkbox"
                      name="metallicSheathing"
                      value="Lead sheathed cables"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "metallicSheathing"
                        ) === "Lead sheathed cables"
                      }
                    />
                    <label htmlFor="ms5">Lead sheathed cables</label>
                  </div>
                  <div className="choice">
                    <input
                      id="ms6"
                      type="checkbox"
                      name="metallicSheathing"
                      value="Copper shielded cables"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "metallicSheathing"
                        ) === "Copper shielded cables"
                      }
                    />
                    <label htmlFor="ms6">Copper shielded cables</label>
                  </div>
                  <div className="choice">
                    <input
                      id="ms7"
                      type="checkbox"
                      name="metallicSheathing"
                      value="Welded Aluminum sheathed cable"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "metallicSheathing"
                        ) === "Welded Aluminum sheathed cable"
                      }
                    />
                    <label htmlFor="ms7">Welded Aluminum sheathed cable</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div>
                <span>Armouring</span>
                <div className="choices">
                  <div className="choice">
                    <input
                      id="ar1"
                      type="checkbox"
                      name="armouring"
                      value="Tape Armoured"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "armouring"
                        ) === "Tape Armoured"
                      }
                    />
                    <label htmlFor="ar1">Tape Armoured</label>
                  </div>
                  <div className="choice">
                    <input
                      id="ar2"
                      type="checkbox"
                      name="armouring"
                      value="Un Armoured"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "armouring"
                        ) === "Un Armoured"
                      }
                    />
                    <label htmlFor="ar2">Un Armoured</label>
                  </div>
                  <div className="choice">
                    <input
                      id="ar3"
                      type="checkbox"
                      name="armouring"
                      value="Wire Armoured"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "armouring"
                        ) === "Wire Armoured"
                      }
                    />
                    <label htmlFor="ar3">Wire Armoured</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div>
                <span>Insulation</span>
                <div className="choices">
                  <div className="choice">
                    <input
                      id="in1"
                      type="checkbox"
                      name="insulation"
                      value="Bare Conductors"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "insulation"
                        ) === "Bare Conductors"
                      }
                    />
                    <label htmlFor="in1">Bare Conductors</label>
                  </div>
                  <div className="choice">
                    <input
                      id="in2"
                      type="checkbox"
                      name="insulation"
                      value="Insulated Conductor"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "insulation"
                        ) === "Insulated Conductor"
                      }
                    />
                    <label htmlFor="in2">Insulated Conductor</label>
                  </div>
                  <div className="choice">
                    <input
                      id="in3"
                      type="checkbox"
                      name="insulation"
                      value="PVC insulated"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "insulation"
                        ) === "PVC insulated"
                      }
                    />
                    <label htmlFor="in3">PVC insulated</label>
                  </div>
                  <div className="choice">
                    <input
                      id="in4"
                      type="checkbox"
                      name="insulation"
                      value="XLPE insulated"
                      onChange={onFilterItemChecked}
                      checked={
                        new URLSearchParams(location.search).get(
                          "insulation"
                        ) === "XLPE insulated"
                      }
                    />
                    <label htmlFor="in4">XLPE insulated</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {renderProducts(
            loading,
            errorMessage,
            categoryItems,
            categoryItemPhotos
          )}
        </div>
        <PaginationComp
          to={`/products/${id}/category-items`}
          activePage={paginationState.activePage}
          totalItems={paginationState.totalItems}
          itemsPerPage={paginationState.itemsPerPage}
          otherParams={getItemParamsFromSearch()}
          attr={{ disabled: loading }}
        />
      </div>
    </main>
  );
};

export default Product;
