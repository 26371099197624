import { Link } from "react-router-dom";
import "./HomeSliderAr.css";

const Slider = ({ num, image, label, description }) => {
  return (
    <div className={`item slides ${num === 1 ? "active" : ""}`}>
      <div
        className={`slide-${num}`}
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div className="hero clearfix">
        <div className="col-sm-12">
          <h3 className="big col headSlider">{label}</h3>
          <p className="col_4 descSlider">{description}</p>
          {/* <h3 className="big col headSlider">لبن طازج</h3>
          <p className="col_4 descSlider">ننتج اللبن الطازج كل يوم</p> */}
          <h5 className="big">
            <Link className="button" to={`/ar/contact`}>
              طـلب تســعيره
            </Link>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Slider;
