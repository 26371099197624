/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, Fragment, useRef } from "react";
import axios from "axios";
import { Alert } from "reactstrap";

import Modal from "../../../components/Modal/Modal";
import "../../Certificate/Certificate.css";
import "./CertificateAr.css";

function certificatesRow(subData, setModalData, itemPhotos) {
  return (
    <>
      {subData.map((cert) => {
        return (
          <div className="col-md-3" key={cert.id}>
            <div className="serv_2i">
              <div className="serv_2i2 position-relative">
                <div className="serv_2i2i">
                  <div className="grid">
                    <figure className="effect-jazz certiimage new mb-0">
                      <a
                        onClick={() => {
                          setModalData({
                            showModal: true,
                            media: itemPhotos[cert.id]?.photoContentType
                              ? `data:${
                                  itemPhotos[cert.id].photoContentType
                                };base64,${itemPhotos[cert.id].photo}`
                              : "",
                          });
                        }}
                      >
                        <img
                          loading="lazy"
                          src={
                            itemPhotos[cert.id]?.photoContentType
                              ? `data:${
                                  itemPhotos[cert.id].photoContentType
                                };base64,${itemPhotos[cert.id].photo}`
                              : ""
                          }
                          className="w-100"
                          alt="certificate"
                        />
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
function renderData(loading, errorMessage, data, itemPhotos, setModalData) {
  if (loading) {
    return (
      <div className="fetching-data-spinner-parent">
        <div className="fetching-data-spinner"></div>
      </div>
    );
  } else {
    if (errorMessage) {
      return <Alert color="danger">{errorMessage}</Alert>;
    } else {
      return data.length > 0 ? (
        <>
          {[...Array(Math.ceil(data.length / 4)).keys()].map((row, idx) => {
            return (
              <Fragment key={idx}>
                {idx > 0 && (
                  <>
                    <br />
                    <hr className="style14" />
                    <br />
                  </>
                )}
                <div className="row serv_2 ">
                  {certificatesRow(
                    data.slice(idx * 4, idx * 4 + 4),
                    setModalData,
                    itemPhotos
                  )}
                </div>
              </Fragment>
            );
          })}
        </>
      ) : (
        <Alert color="warning">No data found</Alert>
      );
    }
  }
}

const CertificateAr = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [certificates, setCertificates] = useState([]);
  const [certificatesPhotos, setCertificatesPhotos] = useState({});
  const [modalData, setModalData] = useState({ showModal: false, media: "" });
  const cache = useRef({});

  useEffect(() => {
    const getCertificatePhoto = async (id) => {
      const url = `certificate/getCertificateById/${id}`;
      if (cache.current[url]) {
        const data = cache.current[url];
        setCertificatesPhotos((curPhotos) => {
          return { ...curPhotos, [id]: data };
        });
      } else {
        try {
          const { data } = await axios.get(url);
          if (!data.error) {
            cache.current[url] = data.gallery;
            setCertificatesPhotos((curPhotos) => {
              return { ...curPhotos, [id]: data.certificate };
            });
          }
        } catch (error) {
          console.error(error.message);
        }
      }
    };
    const getCertificates = async () => {
      const url = "certificate/viewAllCertificates";
      setErrorMessage(undefined);
      setLoading(true);
      if (cache.current[url]) {
        const data = cache.current[url];
        setCertificates(data);
        setLoading(false);
        data.forEach((item) => {
          getCertificatePhoto(item.id);
        });
      } else {
        try {
          const { data } = await axios.get(`certificate/viewAllCertificates`);
          if (data.error) {
            if (data.error.includes("REP")) setCertificates([]);
            else setErrorMessage(data.error);
            setLoading(false);
          } else {
            cache.current[url] = data.gallery;
            setCertificates(data.certificates);
            setLoading(false);
            data.certificates.forEach((item) => {
              getCertificatePhoto(item.id);
            });
          }
        } catch (error) {
          setErrorMessage(error.message);
        }
      }
    };

    getCertificates();
  }, []);

  return (
    <main className="certificate-ar">
      <Modal
        showModal={modalData.showModal}
        type="photo"
        setModalData={setModalData}
      >
        <img loading="lazy" src={modalData.media} alt="" />
      </Modal>

      <div className="main clearfix">
        <div className="main_1 clearfix">
          <section id="center" className="center_home clearfix">
            <div
              className="carousel fade-carousel slide"
              data-ride="carousel"
              data-interval="4000"
              id="bs-carousel"
            >
              {/* Overlay */}
              <div className="overlay" />
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item slides active">
                  <div className="slide-26" />
                  <div className="hero clearfix">
                    <div className="col-sm-12">
                      <h3 className="new">الشـهادات</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <section id="serv">
        <div className="container">
          <div className="row serv_1 text-center mb-3">
            <div className="col-md-12">
              <h2 className="mb-0">الشـهادات </h2>
              <span style={{ fontSize: 40 }}>
                <i className="fa fa-book col_4" />
              </span>
            </div>
          </div>
          {renderData(
            loading,
            errorMessage,
            certificates,
            certificatesPhotos,
            setModalData
          )}
        </div>
      </section>
    </main>
  );
};

export default CertificateAr;
