import React from "react";

const ExperienceYearAr = ({ year, description, reverse, alignDirection }) => {
  return (
    <div
      className={`row timeline-element ${reverse ? "reverse" : ""} separline`}
    >
      <div className="timeline-date-panel col-xs-12 col-md-6 align-left">
        <div className="time-line-date-content">
          <p className="mbr-timeline-date mbr-fonts-style display-font">
            {year}
          </p>
        </div>
      </div>
      <span className="iconBackground" />
      <div className={`col-xs-12 col-md-6 align-${alignDirection}`}>
        <div className="timeline-text-content">
          <p className="mbr-timeline-text mbr-fonts-style display-7">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExperienceYearAr;
